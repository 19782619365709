import React from "react";
import config from './config'
import ErrorBoundary from './components/ErrorBoundary'
import find from "lodash/find";
import map from "lodash/map";
import loadable from '@loadable/component'
import Spinner from '~/components/Spinner';

const popupWhitelist = ['RichText', 'RawHtml'];
let moduleCount = 0;

export const applyModuleData = (layouts, modules, metadataMap,data = null) => {
  return map(layouts, (moduleLayout) => {
    const { id, view } = moduleLayout
    const moduleData = data || find(modules, { id: id })
    const moduleName = config.mmap[view];
    if (!moduleName) {
      return console.log(`Module not found for '${view}' id '${id}'`)
    }
    
    if(window.settings.isMonetizationModelEnabled){
      const hasOldVideoModule = find(layouts, {view: "AC VideoPlayerWithInfo 01"}) || find(layouts, {view: "AC VideoPlayerWithInfo 02"})
      const hasNewVideoModule = find(layouts, {view: "AC VideoPlayerWithInfo 06"}) || find(layouts, {view: "AC VideoPlayerWithInfo 07"})
      if(hasOldVideoModule && hasNewVideoModule){
        if(view === "AC VideoPlayerWithInfo 01" || view === "AC VideoPlayerWithInfo 02"){
          return console.log(`Ignoring old Module for '${view}' id '${id}'`)
        }
      }
      const hasOldUserManagementModule = find(layouts, {view: "AC UserManagement 01"}) || find(layouts, {view: "AC UserManagement 02"})
      const hasNewUserManagementModule = find(layouts, {view: "AC UserManagement 04"})
      if(hasOldUserManagementModule && hasNewUserManagementModule){
        if(view === "AC UserManagement 01" || view === "AC UserManagement 02"){
          return console.log(`Ignoring old Module for '${view}' id '${id}'`)
        }
      }

      const hasOldStandAlonePlayerModule = find(layouts, {view: "AC StandaloneVideoPlayer 01"})
      const hasNewStandAlonePlayerModule = find(layouts, {view: "AC StandaloneVideoPlayer 04"})
      if(hasOldStandAlonePlayerModule && hasNewStandAlonePlayerModule){
        if(view === "AC StandaloneVideoPlayer 01"){
          return console.log(`Ignoring old Module for '${view}' id '${id}'`)
        }
      }

      const hasOldShowDetailModule = find(layouts, {view: "AC ShowDetail 01"})
      const hasNewShowDetailModule = find(layouts, {view: "AC ShowDetail 08"})
      if(hasOldShowDetailModule && hasNewShowDetailModule){
        if(view === "AC ShowDetail 01"){
          return console.log(`Ignoring old Module for '${view}' id '${id}'`)
        }
      }

      const hasOldBundleDetailModule = find(layouts, {view: "AC BundleDetail 01"})
      const hasNewBundleDetailModule = find(layouts, {view: "AC BundleDetail 02"})
      if(hasOldBundleDetailModule && hasNewBundleDetailModule){
        if(view === "AC BundleDetail 01"){
          return console.log(`Ignoring old Module for '${view}' id '${id}'`)
        }
      }

      const hasOldRedeemModule = find(layouts, {view: "AC RedeemOffer 01"})
      const hasNewRedeemModule = find(layouts, {view: "AC RedeemOffer 02"})
      if(hasOldRedeemModule && hasNewRedeemModule){
        if(view === "AC RedeemOffer 01"){
          return console.log(`Ignoring old Module for '${view}' id '${id}'`)
        }
      }
    }else{
      if(view === "AC VideoPlayerWithInfo 06" || view === "AC VideoPlayerWithInfo 07" || view === "AC UserManagement 04" || view === "AC StandaloneVideoPlayer 04" || view === "AC ShowDetail 08" || view === "AC BundleDetail 02" || view === "AC RedeemOffer 02"){
        return console.log(`Ignoring new Module for '${view}' id '${id}'`)
      }
    }

    if (popupWhitelist.includes(moduleName)) {
      import('./views/Popup/popup').then(popup => {
        window.popup = popup.default;
      });
    }
    const Module = loadable( (props) => import(`./modules/${props.moduleName}`),{
      fallback: Spinner
    });
    const alignment = (moduleData && moduleData.metadataMap !== null && moduleData.metadataMap && moduleData.metadataMap.align) ? moduleData.metadataMap.align : '';
    const fullAlignment = (moduleLayout && 
      ((moduleLayout.view.includes("StandaloneVideoPlayer") && (!moduleLayout.blockName.includes("standaloneVideoPlayer02") && !moduleLayout.blockName.includes("standaloneVideoPlayer03"))) && (moduleData && moduleData.contentData && moduleData.contentData.length) && (moduleLayout.settings && !moduleLayout.settings.hidePlayerOnWeb))) ? "full" : '';
      const fullCarouselAlignment = (moduleLayout && 
        (moduleLayout.view.includes("Carousel") && (!moduleLayout.blockName.includes("carousel04") && !moduleLayout.blockName.includes("eventCarousel01") && !moduleLayout.blockName.includes("eventCarousel02")))) ? "fullCarousel" : '';  
    const halfAlignment = (moduleLayout && (moduleLayout.blockName.includes("carousel04") || moduleLayout.blockName.includes("eventCarousel02"))) ? "half" : '';
    if(moduleData){
      if(((moduleName.toLowerCase()).includes("carousel") || (moduleName.toLowerCase()).includes("tray")) && !(moduleName.toLowerCase()).includes("recommendation") && ((moduleData.contentData === null || moduleData.contentData.length === 0) && !moduleName.includes("PageTray2")) && !moduleName.includes("Carousel/ImageCarousel")){
           return
      }
      else{
      moduleCount = moduleCount + 1
    return <ErrorBoundary key={id}>
      <div className={`module ${alignment} ${fullAlignment} ${fullCarouselAlignment} ${halfAlignment}`}>
        <Module data={moduleData} layout={moduleLayout} moduleName={moduleName} metadataMap={moduleName.includes("PageTray2") ? metadataMap : {} }/>
      </div>
    </ErrorBoundary >
    }} else {
      return null;
    }
  })
}

export const nestTabModules = (layouts, modules, tabModule) => {
  let footers = layouts.filter(moduleLayout => moduleLayout.view.includes("Footer"));
  let headers = layouts.filter(moduleLayout => moduleLayout.view.includes("Masthead"));
  let allOtherContent = layouts.filter(moduleLayout => !moduleLayout.view.includes('Footer') && !moduleLayout.view.includes('Masthead') && !moduleLayout.view.includes('Tabs'))

  footers = applyModuleData(footers, modules)
  headers = applyModuleData(headers, modules)
  allOtherContent = applyModuleData(allOtherContent, modules);
  tabModule = applyModuleData(tabModule, modules, allOtherContent);

  return [...headers, tabModule, ...footers]
}
