// Start Adwords Dynamic Conversion Tracking
window.dataLayer = window.dataLayer || [];

const GoogleAdwordsID = window.settings.analytics && window.settings.analytics.googleAdwordsId;
const GoogleAdwordsConversionLabel = window.settings.analytics && window.settings.analytics.googleAdwordsConversionLabel;
const GoogleAnalyticsID = window.settings.analytics && window.settings.analytics.googleAnalyticsId;
const GoogleAdsRemarketingID = window.settings.analytics && window.settings.analytics.googleAdsRemarketingId;
const GoogleAnalyticsAppWebID = window.settings.analytics && window.settings.analytics.googleAnalyticsAppWebId;

const gtmAdwords = () => {
    return new Promise (function(resolve, reject){

        if(GoogleAdwordsID) {
            var script = document.createElement('script');
            script.type= 'text/javascript';
            script.async = true;
            script.src = 'https://www.googletagmanager.com/gtag/js?id='+ GoogleAdwordsID;
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        }
    })
}

function gtag(){window.dataLayer.push(arguments);}

export const initGtmAdwords = () => {
    if(GoogleAdwordsID){
        gtmAdwords()
        .then(()=> {
            gtag('js', new Date());

            if(GoogleAdwordsID)
                gtag('config', GoogleAdwordsID); // For Google Adwords
            if(GoogleAnalyticsID) 
                gtag('config', GoogleAnalyticsID); // For Google Analytics
            if(GoogleAdsRemarketingID) 
                gtag('config', GoogleAdsRemarketingID); // For Google Ads Remarketing
            if(GoogleAnalyticsAppWebID) 
                gtag('config', GoogleAnalyticsAppWebID); // For Google Analytics App + Web stream
        })
    }
}

export function trackAdwordsEvent(eventData, eventName = 'conversion') {
    GoogleAdwordsID && gtag('event', eventName, {
        // 'send_to': GoogleAdwordsID+'/MaohCJbvnrgBEJPl45YD',
        'send_to': GoogleAdwordsID + '/' + GoogleAdwordsConversionLabel,
        eventData
    });
}
// End Adwords Dynamic Conversion Tracking

// Start Google Ads Remarketing
// Event snippet for Example dynamic remarketing page
export function adsRemarketingEvent(eventName = 'page_view', eventData) {
    GoogleAdsRemarketingID && gtag('event', eventName, {
        'send_to': GoogleAdsRemarketingID,
        eventData
      });
}
// End Google Ads Remarketing

// Start Google ecommerce
// route ecommerce add_to_cart event to Google Ads and Analytics
export function ecommerceEvent(eventName = 'add_to_cart', eventItems) {
    GoogleAdwordsID && gtag('event', eventName, {
      'send_to': [
          GoogleAdwordsID
      ],
      'items': eventItems
    });
}
// End Google ecommerce

// Start Google Analytics: App + Web Configuration
// route ecommerce add_to_cart event to Google Ads and Analytics
export function webConfigurationEvent(eventName = 'page_view', eventData) {
    GoogleAnalyticsAppWebID && gtag('event', eventName, {
      'send_to': GoogleAnalyticsAppWebID,
      eventData
    });
}


export const gtmTagEcommerceEvent = (props, eventName, paymentMode) => {
    const { plan, user } = props
    const pd = (plan && plan.planDetails && plan.planDetails[0]) || {}
    const planDetails = (pd && pd.description) || (plan && plan.description)
    const planAmount = pd && (pd.introductoryPrice || pd.recurringPaymentAmount)
    const countryCode = pd && pd.countryCode
    const currencyCode = pd && pd.recurringPaymentCurrencyCode
    const planDuration = pd && pd.title

    const dt = new Date();
    const currentDate =  dt.getDate() + "/" + dt.getMonth() + "/" + dt.getFullYear();

    const eventItems = {
        'Name': user.name,
        'Email': user.email,
        'userId': user.userId,
        'subscriptionPlan': planDetails,
        'subscriptionStartDate': currentDate,
        'subscriptionEndDate': planDuration,
        'transactionAmount': planAmount,
        'paymentHandler': paymentMode,
        'subscribed_via_platform': 'Web',
        'subscribed_from_country': countryCode,
        'localCurrency': currencyCode,
    } 
    ecommerceEvent(eventName, eventItems)
}
// End Google Analytics: App + Web Configuration