import React from 'react'
import each from 'lodash/each';
import find from 'lodash/find';
import './style.scss'

function getNodeToFocus() {
  let nodeToFocus

  each(document.querySelectorAll('.module'), module => {
    if (module.querySelector('nav')) 
      return 
      
    let firstFocusableNode = getFirstFocusableNode(module)
    if (firstFocusableNode) {
      nodeToFocus = firstFocusableNode
      return false
    }
  })

  return nodeToFocus
}

function getFirstFocusableNode(node) {
  const focusableNodes = node.querySelectorAll('a, button, iframe, [tabIndex="0"]')
  return find( focusableNodes, node => !node.classList.contains('hide') && node.tabIndex > -1 )
}

class NavFocusSkipper extends React.PureComponent {
  constructor() {
    super()
    this.skipToMainContent = this.skipToMainContent.bind(this)
  }

  skipToMainContent() {
    if (!this.firstMainContentNode)
      this.firstMainContentNode = getNodeToFocus()
    
    try {
      this.firstMainContentNode.focus()  
    } catch(e) {
      console.error('No actionable elements in main content')
    }
  }

  render() {
    return (
      <button 
        className="skip-nav"
        onClick={this.skipToMainContent}
      >
        Skip to main content
      </button>
    )
  }
}

export default NavFocusSkipper