import axios from 'axios'
import { getToken } from './tokens'
import { subscribe as _subscribe } from './actions'
import { cancelSubscribe as _cancelSubscribe } from './actions'
import store from './store'
import jwtDecode from 'jwt-decode'
import "core-js/modules/es6.promise";
import "core-js/modules/es6.array.iterator";
import { fetchUserSubscription } from './helpers'
export var subscribe = (o) => {
  return store.dispatch(_subscribe(o))
}

export var cancelSubscribe = (o) => {
	return store.dispatch(_cancelSubscribe(o))
}

export const validateSubscriptionOfferCode = async (offerCode) => {
	const token = await getToken()
	try {
		const response = await axios({
			method: 'POST',
			url: `${ window.settings.apiBaseUrl }/subscription/offer/validate`,
			params: { site: window.settings.internalName },
			data: {
				offerCode: offerCode
			},
			headers: { 
				Authorization: token,
				'x-api-key': window.settings.baseAPIKey
			}
		})
		return {
			isError: false,
			data: response.data
		}
	} catch (error) {
		return {
			isError: true,
			error: (error.response && error.response.data && error.response.data.message) || 'An error occuredd. Try again later'
		}
	}
}

export const validateTransactionOfferCode = async (couponCode) => {
	const token = await getToken()
	try {
		const response = await axios({
			method: 'POST',
			url: `${ window.settings.apiBaseUrl }/subscription/transaction/validate`,
			params: { site: window.settings.internalName },
			data: {
				couponCode: couponCode
			},
			headers: { 
				Authorization: token,
				'x-api-key': window.settings.baseAPIKey
			}
		})
		return {
			isError: false,
			data: response.data
		}
	} catch (error) {
		return {
			isError: true,
			error: (error.response && error.response.data && error.response.data.message) || 'An error occuredd. Try again later'
		}
	}
}

export var getDiscountPrice = (data) => {
  return getToken().then(token => {
	var decodedToken = jwtDecode(token);
	var tokenCountryCode = decodedToken.countryCode
   	  const user = store.getState().user;
      return axios ({
        method: 'GET',
        url: `${window.settings.apiBaseUrl}/subscription/calculate/discount`,
        params: {
          userId: (user && user.userId) || null,
          countryCode: tokenCountryCode,
          planId: data.planId,
          site: window.settings.internalName,
		  offerCode: data.offerCode,
		  transactionType: data.transactionType || null
        },
        headers: {
          Authorization: token,
          'x-api-key': window.settings.baseAPIKey
        }
      })
  })
}

export var subscribeBySubscriptionOfferCode = async (offerCode, moduleType) => {
	try{
		const token = await getToken()
    	const user = store.getState().user
		const response = await axios({
			method: 'POST',
			url: `${ window.settings.apiBaseUrl }/subscription/subscribe`,
			params: { site: window.settings.internalName, platform: 'web_browser' },
			data: {
				siteId: window.settings.siteId,
				userId: user.userId,
				subscription: 'prepaid',
				appliedOffers: [offerCode],
			},
			headers: { 
				'Authorization': token,
				'x-api-key': window.settings.baseAPIKey
			}
		})
		if(response.status === 200 && response.data){
			if(moduleType && moduleType === "redeem") {
				user.userId && await fetchUserSubscription();
			}		
			return {
				isError: false,
				data: response.data
			}
		}
		return {
			isError: true,
			error: 'Oops! Something went wrong.'
		}
	}catch(error){
		return {
			isError: true,
			error: (error.response && error.response.data && error.response.data.message) || 'Oops! Something went wrong.'
		}
	}
}

export var subscribeByTransactionOfferCode = async (couponCode) => {
	try{
		const token = await getToken()
		const { siteId, internalName } = window.settings
			const { userId, email } = store.getState().user
    	const data = {
			"transaction":"prepaid",
			"purchaseType":"PURCHASE",
			"platform":"web_browser",
			"currencyCode":"USD",
			"site":internalName,
			siteId,
			userId,
			email,
			couponCode
		}
		const response = await axios({
				method: 'POST',
				url: `${ window.settings.apiBaseUrl }/transaction/transaction`,
				data: data,
				headers: { 
					'Authorization': token,
					'x-api-key': window.settings.baseAPIKey
				}
			})
		if(response.status === 200 && response.data.status === "success"){
			return {
				isError: false,
				data: {
					subscriptionStatus: 'COMPLETED'
				}
			}
		}
		return {
			isError: true,
			error: 'Something went wrong. Please try again later.'
		}
	}catch(error){
		return {
			isError: true,
			error: (error.error) || (error.response && error.response.data && (error.response.data.message || error.response.data.error)) || 'Something went wrong. Please try again later.'
		}
	}
}

export var getSubscriptionPlans = () => {
  return getToken().then( (token, refreshToken) => {
    var user = store.getState().user
	const latitude = (user && user.hbaMetadata && user.hbaMetadata.latitude) || null
	const longitude = (user && user.hbaMetadata && user.hbaMetadata.longitude) || null
	
	let params;
	if(latitude && longitude){
		params = {
			site: window.settings.internalName,
			userId: user && user.userId,
			device: 'web_browser',
			monetizationModel:'SVOD',
			latitude: latitude,
			longitude: longitude
		}
	} else {
		params = {
			site: window.settings.internalName,
			userId: user && user.userId,
			device: 'web_browser',
			monetizationModel:'SVOD'
		}
	}
    return axios({
      method: 'GET',
      url: `${ window.settings.apiBaseUrl }/subscription/plans`,
      params: params,
      headers: { 
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey
      }
    }).then( res => res.data )
  })
}

export var getInvisiblePlans = () => {
  return getToken().then( (token, refreshToken) => {
    var user = store.getState().user
    return axios({
      method: 'GET',
      url: `${ window.settings.apiBaseUrl }/subscription/plans`,
      params: { site: window.settings.internalName,
        userId: user && user.userId,
        ids: window.sessionStorage.getItem('plans'),
        device: 'web_browser', 
        allPlans: true 
      },
      headers: { 
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey
      }
    })
	.then( res => {
		if(res && res.data && res.data.length < 1){
		 window.location.href = window.location.origin;   //redirecting to Home page on No plan/ Expired plan
		}
		return res
	})
  })
}

export var getInvisiblePlansById = (ids) => {
	return getToken().then( (token, refreshToken) => {
	  var user = store.getState().user
	  return axios({
		method: 'GET',
		url: `${ window.settings.apiBaseUrl }/subscription/plans`,
		params: { site: window.settings.internalName,
		  userId: user && user.userId,
		  ids: ids,
		  device: 'web_browser', 
		  allPlans: true 
		},
		headers: { 
		  Authorization: token,
		  'x-api-key': window.settings.baseAPIKey
		}
	  }).then( res => res.data )
	})
  }

export var getPlansById = (ids) => {
	return getToken().then( (token, refreshToken) => {
	  var user = store.getState().user
	  return axios({
		method: 'GET',
		url: `${ window.settings.apiBaseUrl }/subscription/plans`,
		params: { site: window.settings.internalName,
		  userId: user && user.userId,
		  ids: ids,
		  device: 'web_browser',
		  visible: true 
		},
		headers: { 
		  Authorization: token,
		  'x-api-key': window.settings.baseAPIKey
		}
	  }).then( res => res.data )
	})
  }

export var getMyBillingHistory = () => {
  return getToken().then( (token, refreshToken) => {
    return axios({
      method: 'GET',
      url: `${ window.settings.apiBaseUrl }/payments/billing-history`,
      params: { site: window.settings.internalName },
      headers: { 
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey 
      }
    })
  })
}

export var cancelMySubscription = (getSelectedReason = '') => {
  return getToken().then( (token, refreshToken) => {
    return axios({
      method: 'PUT',
      url: `${ window.settings.apiBaseUrl }/subscription/cancel`,
      params: { site: window.settings.internalName },
      headers: { 
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey 
      },
	  data: {
		"cancellationReason": getSelectedReason
	  }
    })
  })
}
export var upgradeMySubscription = (plan, paymentHandler, offerCode) => {
  return getToken().then(token => {
    var user = store.getState().user
    var data ={}
	let appliedOffers = []
	if(offerCode) {
		appliedOffers.push(offerCode)
		data =  {
			subscription: paymentHandler,
			siteId: window.settings.siteId,
			platform: 'web_browser',
			planId: plan.id,
			planIdentifier: plan.identifier,
			userId: user.userId,
			appliedOffers: appliedOffers
		  }
	} else {
		data =  {
			subscription: paymentHandler,
			siteId: window.settings.siteId,
			platform: 'web_browser',
			planId: plan.id,
			planIdentifier: plan.identifier,
			userId: user.userId
		  }
	}

    return axios({
      method: 'PUT',
      url: `${window.settings.apiBaseUrl}/subscription/subscribe`,
      params: { site: window.settings.internalName, platform: 'web_browser' },
      headers: { 
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey 
      },
      data: data
    })
  })
}

export const reactivateSubscription = async () => {
  const token = await getToken();
  const data = {
    action: "reactivate",
    platform: "web_browser",
  };
  return await axios({
    method: "PUT",
    url: `${window.settings.apiBaseUrl}/subscription/subscribe`,
    params: { site: window.settings.internalName, platform: "web_browser" },
    headers: {
      Authorization: token,
      "x-api-key": window.settings.baseAPIKey,
    },
    data: data,
  });
};

// Stripe
export var updatePayment = (o) => {
  return getToken().then( (token, refreshToken) => {
    return axios({
      method: 'PUT',
      url: `${ window.settings.apiBaseUrl }/payments/payment-instrument`,
      params: { site: window.settings.internalName },
      data: {
        stripeToken: o.id,
        siteId: window.settings.siteId,
      },
      headers: { 
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey 
      }
    })
  })
}

export const validateSubscriptionOfferCodeByPlanId = async (payload) => {
	const token = await getToken()
	try {
		const response = await axios({
			method: 'POST',
			url: `${ window.settings.apiBaseUrl }/subscription/offer/validate`,
			params: { site: window.settings.internalName },
			data: payload,
			headers: { 
				Authorization: token,
				'x-api-key': window.settings.baseAPIKey
			}
		})
		return {
			isError: false,
			data: response.data
		}
	} catch (error) {
		return {
			isError: true,
			code:(error.response && error.response.data && error.response.data.code),
			error: (error.response && error.response.data && error.response.data.message) || 'An error occuredd. Try again later'
		}
	}
}
