 const googleAnalytics = () => {
  if (window.SERVER_SIDE_RENDERING)
    return

  if(window.settings.analytics)
  {
    var gtmId = window.settings.analytics.googleTagManagerId
  }
  
  if (!gtmId)
    return

  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!=='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window, window.document,'script','dataLayer', gtmId);
}

export default googleAnalytics;