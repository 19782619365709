import _axios from 'axios'

export const axiosWithKey = _axios.create({
  baseURL: window.settings.apiBaseUrl,
  params: {
    site: window.settings.internalName
  },
  headers: { 
    'x-api-key': window.settings.baseAPIKey
  }
})

export const axios = _axios.create({
  baseURL: window.settings.apiBaseUrl,
  params: {
    site: window.settings.internalName
  }
})