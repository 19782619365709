function connectStore(C, translator, store) {
  var update = function() {
    var state = store.getState()
    this.setState({...this.state, ...translator(state)})
  }

  var componentWillMount = C.prototype.componentWillMount
  C.prototype.componentWillMount = function() {
    update.call(this)
    if (!window.DONT_LISTEN_HACK)
      this.unsubscribeFromStore = store.subscribe( _ => {
        update.call(this)
      })
    if (componentWillMount)
      componentWillMount.call(this)
  } 

  var componentWillUnmount = C.prototype.componentWillUnmount
  C.prototype.componentWillUnmount = function() {
    if (this.unsubscribeFromStore)
      this.unsubscribeFromStore()
    if (componentWillUnmount)
      componentWillUnmount.call(this)
  }
}

export default connectStore