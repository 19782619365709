import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const getBorderStyle = s => !s ? '' : `
  border-style: solid;
  border-radius: ${s.radius || '0px'};
  ${ s.width ? `border-width: ${ s.width };` : '' }
  ${ s.color ? `border-color: ${ s.color.hex };` : '' }
`

const getBlockStyle = s => !s ? '' : `
  ${ s.background ? `background: ${ s.background };` : '' }
  ${ s.backgroundColor ? `background: ${ s.backgroundColor };` : '' }
  ${ s.color ? `color: ${ s.color };` : '' }
  ${ s.textColor ? `color: ${ s.textColor };` : '' }
  ${ getBorderStyle(s.border) }
`

const getStatedBlockStyle = (cls, s, key ) => !s ? '' : `
  ${cls} { ${ getBlockStyle(s[key]) } }
  ${cls}:hover { ${ getBlockStyle(s[key + '--hover']) } }
  ${cls}:active { ${ getBlockStyle(s[key + '--active']) } }
`

const pagestylecss = s => !s? '':`
    .appcms, body {
    ${ getBlockStyle(s.general) }
    }
    ${ getStatedBlockStyle( '.appcms .link', s, 'link' ) }
    ${ getStatedBlockStyle( '.rich-text a', s, 'link' ) }
    :root {
      ${ get(s.cta, 'primary.backgroundColor') ? `--site-color: ${ s.cta.primary.backgroundColor };` : ''}
    }
    .appcms .site-color {
      ${ get(s.cta, 'primary.backgroundColor') ? `color: ${ s.cta.primary.backgroundColor };` : '' }
    }
    .appcms .site-border-color {
      ${ get(s.cta, 'primary.backgroundColor') ? `border-color: ${ s.cta.primary.backgroundColor };` : '' }
    }
    .appcms .site-background-color {
      ${ get(s.cta, 'primary.backgroundColor') ? `background-color: ${ s.cta.primary.backgroundColor };` : '' }
    }
    .appcms .site-color-before:before {
      ${ get(s.cta, 'primary.backgroundColor') ? `color: ${ s.cta.primary.backgroundColor };` : '' }
    }
    .appcms .site-color-after:after {
      ${ get(s.cta, 'primary.backgroundColor') ? `color: ${ s.cta.primary.backgroundColor };` : '' }
    }
    .video-tray .slick-next, .video-tray .slick-next:before, .video-tray .slick-prev, .video-tray .slick-prev:before {
      ${ get(s.cta, 'primary.backgroundColor') ? `color: ${ s.cta.primary.backgroundColor };` : '' }
    }
    ${ getStatedBlockStyle( '.appcms .cta', s.cta, 'primary' ) }
    ${ getStatedBlockStyle( '.appcms .cta.secondary', s.cta, 'secondary' ) }`

  export const updatepageStyle = (pageStyles) => {
    const pageStyle = pagestylecss(pageStyles);
    if(!isEmpty(pageStyle) && !document.querySelector('#page-style')) {
        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = pageStyle;
        style.id = 'page-style';
        document.head.appendChild(style);
    }
  }