import React from 'react'
import './style.css'

const PageSpinner = (props) => {
  var style = {
    "color": props.color
  }

  return <div className="pageSpinner page-site-color" style={style}></div>  
}

export default PageSpinner
