const insertKetchCookie = () => {
    const isKetchCookieConsentEnable = (window.settings && window.settings.privacy && window.settings.privacy.ketchCookiesConsent && window.settings.privacy.ketchCookiesConsent.enableKetchCookiesConsent) || null;
    const orgName = (window.settings && window.settings.privacy && window.settings.privacy.ketchCookiesConsent && window.settings.privacy.ketchCookiesConsent.orgCode) || null;
    const webPropertyCode = (window.settings && window.settings.privacy && window.settings.privacy.ketchCookiesConsent && window.settings.privacy.ketchCookiesConsent.webPropertyCode) || null;
   
    if(isKetchCookieConsentEnable && webPropertyCode && orgName){
        console.log("initiate ketch cookie consent")
        const params = new URLSearchParams(document.location.search);
        const propertyCode = (params && params.has("property")) ? params.get("property") : webPropertyCode;   
        
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `https://global.ketchcdn.com/web/v2/config/${orgName}/${propertyCode}/boot.js`;
        script.defer = true;
        script.async = true;
    
        document.head.appendChild(script);
    }
}

export default insertKetchCookie