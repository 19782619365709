const { site, brand, images, domainName } = window.settings
const {  cta } = brand
const notifications = window.settings.notifications || {}
const webPush = notifications.webPush ? notifications.webPush : {}
const anonymousUserWebPush = notifications.anonymousUserWebPush ? notifications.anonymousUserWebPush : {}
const gcm_sender_id = webPush.gcm_sender_id ? webPush.gcm_sender_id.toString() : (anonymousUserWebPush.gcm_sender_id ? anonymousUserWebPush.gcm_sender_id.toString() : null)
const isPWAEnabled = window && window.settings && window.settings.features && window.settings.features.enablePWA
const isCleverTapEnabled = window && window.settings && window.settings.cleverTapAnalyticsId
const webManifest = {
	"name": site,
	"short_name": site,
	"background_color": cta.primary.backgroundColor,
	"icons": [
		{
		  "src": (images && images.pwaLogo && images.pwaLogo.pwaLogo72x72) || "",
		  "sizes": "72x72",
		  "type": "image/png"
		},
		{
		  "src": (images && images.pwaLogo && images.pwaLogo.pwaLogo96x96) || "",
		  "sizes": "96x96",
		  "type": "image/png"
		},
		{
		  "src": (images && images.pwaLogo && images.pwaLogo.pwaLogo128x128) || "",
		  "sizes": "128x128",
		  "type": "image/png"
		},
		{
		  "src": (images && images.pwaLogo && images.pwaLogo.pwaLogo144x144) || "",
		  "sizes": "144x144",
		  "type": "image/png"
		},
		{
		  "src": (images && images.pwaLogo && images.pwaLogo.pwaLogo152x152) || "",
		  "sizes": "152x152",
		  "type": "image/png"
		},
		{
		  "src": (images && images.pwaLogo && images.pwaLogo.pwaLogo192x192) || "",
		  "sizes": "192x192",
		  "type": "image/png",
		  "purpose": "any maskable"
		},
		{
		  "src": (images && images.pwaLogo && images.pwaLogo.pwaLogo384x384) || "",
		  "sizes": "384x384",
		  "type": "image/png"
		},
		{
		  "src": (images && images.pwaLogo && images.pwaLogo.pwaLogo512x512) || "",
		  "sizes": "512x512",
		  "type": "image/png"
		}
	  ],
	"start_url": "https://"+domainName,
	"theme_color": cta.primary.backgroundColor,
	"display": "standalone",
	"gcm_user_visible_only": true,
	"gcm_sender_id": gcm_sender_id
}

const clevertapWebManifest = {
	"gcm_user_visible_only": true,
	"gcm_sender_id": gcm_sender_id

}

export const initializeWebWorker = () => {
	if (isPWAEnabled) {
		const blob = new Blob([JSON.stringify(webManifest)], { type: 'application/json' })
		const manifestURL = URL.createObjectURL(blob)
		document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);
	} else {
		if (isCleverTapEnabled) {
			const blob = new Blob([JSON.stringify(clevertapWebManifest)], { type: 'application/json' })
			const manifestURL = URL.createObjectURL(blob)
			document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);
		}
	}
}

export const initializeServiceWorker = () => {
	if (!isPWAEnabled) {
		('service-worker' in localStorage) && localStorage.removeItem('service-worker')
		navigator && navigator.serviceWorker && navigator.serviceWorker.getRegistrations() && navigator.serviceWorker.getRegistrations().then((registrations) => {
			registrations.map((registration) => {
				const url = (registration && registration.active && registration.active.scriptURL) || ""
				if (url.toLowerCase().includes('serviceWorker.js'.toLowerCase())) {
					registration.unregister()
				}
			})
		})
	}
	if (!isCleverTapEnabled) {
		('clevertap-sw' in localStorage) && localStorage.removeItem('clevertap-sw')
		navigator && navigator.serviceWorker && navigator.serviceWorker.getRegistrations() && navigator.serviceWorker.getRegistrations().then((registrations) => {
			registrations.map((registration) => {
				const url = (registration && registration.active && registration.active.scriptURL) || ""
				if (url.toLowerCase().includes('sw.js'.toLowerCase())) {
					registration.unregister();
				}
			})
		})
	}
	if ((isPWAEnabled) && (window && window.localStorage && window.localStorage.getItem('service-worker') !== 'activated') && 'serviceWorker' in navigator) {
		('clevertap-sw' in localStorage) && localStorage.removeItem('clevertap-sw')
		window.addEventListener('load', () => {
			navigator.serviceWorker.register("/serviceWorker.js")
				.then(() => {
					localStorage.setItem('service-worker', 'activated')
				})
				.catch(err => {
					console.log("Error Registering Service Worker"+err)
				});
		});
	} else {
		if ((isCleverTapEnabled) && (window && window.localStorage && window.localStorage.getItem('service-worker') !== 'activated') && (window && window.localStorage && window.localStorage.getItem('clevertap-sw') !== 'activated') && 'serviceWorker' in navigator) {
			window.addEventListener('load', () => {
				navigator.serviceWorker.register("/sw.js")
					.then(() => {
						localStorage.setItem('clevertap-sw', 'activated')
					})
					.catch(err => {
						console.log("Error Registering CleverTap Service Worker"+err)
					});
			});
		}
	}
}

export const requestUserPermission = () => {
	if(window.clevertap){
		window.clevertap.notifications.push({
			"titleText": webPush.titleText,
			"bodyText": webPush.bodyText,
			"okButtonText": webPush.okButtonText,
			"rejectButtonText": webPush.rejectButtonText,
			"okButtonColor": cta.primary.backgroundColor,
			"serviceWorkerPath": '/sw.js'
		})		
	}
}

export const anonymousUserPushNotification = () => {
	if(window.clevertap){
		window.clevertap.notifications.push({
			"titleText": anonymousUserWebPush.titleText,
			"bodyText": anonymousUserWebPush.bodyText,
			"okButtonText": anonymousUserWebPush.okButtonText,
			"rejectButtonText": anonymousUserWebPush.rejectButtonText,
			"okButtonColor": cta.primary.backgroundColor,
			"serviceWorkerPath": '/sw.js'
		})		
	}
}

/*****
 * Remove the anonymous user push notification after user login/signup For showing regular push notification.
 */
export const removePushNotification = () => {
	localStorage.getItem('removePushNotificationFlag') && localStorage.removeItem('removePushNotificationFlag');
	if(notifications && notifications.enableWebPushNotification && notifications.enableAnonymousUserWebPushNotification){
		localStorage.getItem('WZRK_META') && localStorage.removeItem('WZRK_META');
	}
}

/*****
 * Set flag for remove push notification.
 */
export const setRemovePushNotificationFlag = () => {
	if(notifications && notifications.enableWebPushNotification && notifications.enableAnonymousUserWebPushNotification){
		localStorage.setItem('removePushNotificationFlag', true)
	}
}

/*****
 * Get flag for remove push notification.
 */
export const getRemovePushNotificationFlag = () => {
	if(notifications && notifications.enableWebPushNotification && notifications.enableAnonymousUserWebPushNotification){
		return localStorage.getItem('removePushNotificationFlag') ? localStorage.getItem('removePushNotificationFlag') : '';
	} else {
		return '';
	}
}