import { getToken } from '../user/tokens'
import axios from 'axios'
import { logout } from '~/redux/user/helpers'
import userStore from '~/redux/user/store'
import Cookies from 'js-cookie'

export function removeDevice(deviceId, platform) {
  return getToken().then( token => {
    return axios({
      method: 'DELETE',
      url: `${window.settings.apiBaseUrl}/user/device/desync`,
      // url: `https://develop-api.viewlift.com/identity/device/${deviceId}`,
      headers: { 
        'Authorization': token,
        'x-api-key': window.settings.baseAPIKey
      },
      params: {
        site: window.settings.internalName,
        deviceId: deviceId,
        platform: platform
      }
    }).then( res => {
      let currentDevice = localStorage.getItem('deviceId');
      if(currentDevice === deviceId) {
        logout()
      }
    }).catch( err => {
      console.log('Could not remove device')
    })
  })
}

export const removeAllDevice = () => {
  return getToken().then( token => {
    return axios({
      method: 'DELETE',
      url: `${window.settings.apiBaseUrl}/user/device/desync`,
      headers: { 
        'Authorization': token,
        'x-api-key': window.settings.baseAPIKey
      },
      params: {
        site: window.settings.internalName
      }
    })
    .catch( err => {
      console.log('Could not remove device')
    })
  })
}

export function fetchDevices(userId) {
  return getToken().then( token => {
    return axios({
      method: 'GET',
      url: `${window.settings.apiBaseUrl}/user/device?site=${window.settings.internalName}&userId=${userId}`,
      // url: 'https://develop-api.viewlift.com/identity/device?site=snagfilms&userId=aws-111',
      headers: { 
        'Authorization': token,
        'x-api-key': window.settings.baseAPIKey
      }
    })
    .then(x => {
      const userInfo = userStore.getState().user || {}
      const subscriptionData = userStore.getState().subscription|| {}
      const allowedDevices = subscriptionData.subscription && subscriptionData.subscription.subscriptionInfo && subscriptionData.subscription.subscriptionInfo.numberOfAllowedDevices
      let currentDevicesCount = (x && x.data && x.data.Count) || null
      let errorState = Cookies.get('remove_error')
      if(!errorState && userInfo && allowedDevices && currentDevicesCount && allowedDevices >= currentDevicesCount) {
        if(userInfo.code && userInfo.code === "DEVICE_LIMIT_EXCEEDED"){
          Cookies.set('remove_error', true);
          window.location.reload();
        }
      }
      return (x && x.data && x.data.Items) || []
    })
  })
}


export function activateDevice(code, userId) {
  return getToken().then(token => {
    return axios({
      method: 'POST',
      url: `${window.settings.apiBaseUrl}/user/device/sync?site=${window.settings.internalName}`,
      // url: 'https://develop-api.viewlift.com/identity/device?site=snagfilms&userId=aws-111',
      headers: { 
       'Authorization': token,
       'x-api-key': window.settings.baseAPIKey
      },
      data: {
        'activationCode': `${code}`,
        'userId': `${userId}`
      }
    })
  })
}

