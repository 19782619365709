export default {
  value: false,
  selected: {},
  current: null,
  modalType: "",
  id: {},
  args: {
    params: []
  },
};
