import { getToken } from "~/redux/user/tokens";
import { axiosWithKey } from "~/axios";
import get from "lodash/get";
import axios from "axios";
import Cookies from "js-cookie";

export const fetchProfile = (handler, errHandler = () => null) => {
  getToken((token) => {
    let baseUrl = window.settings.apiBaseUrl;
    var contentApiHeaders = {
      Authorization: token,
      "x-api-key": window.settings.baseAPIKey,
    };
    return axiosWithKey({
      method: "GET",
      url: `${baseUrl}/user/profile`,
      headers: contentApiHeaders,
    })
      .then((res) => {
        const result = get(res, "data.items.0", {});
        Cookies.set("user-profile", JSON.stringify(result), { expires: 1 });
        handler(result);
      })
      .catch(err => {
        Cookies.set("user-profile", "{}", { expires: 1 });
        errHandler();
      });
  });
};

export const updateProfile = async (type, metadata = {}, profileId, successHandler, errorHandler) => {
  let response = { status: "error", message: "Default Error" };
  if (!profileId || !metadata || (metadata && Object.keys(metadata).length < 1)) {
    return response;
  }
  await getToken(async (token) => {
    let baseUrl = window.settings.apiBaseUrl;
    var contentApiHeaders = {
      Authorization: token,
      "x-api-key": window.settings.baseAPIKey,
    };
    const { internalName } = window.settings;

    let obj;
    if (type === "post") {
      obj = {
        method: "POST",
        url: `${baseUrl}/user/profile/?site=${internalName}`,
        headers: contentApiHeaders,
        data: {
          metadata: { ...metadata },
          profileName: profileId,
        },
      };
    } else {
      obj = {
        method: "PUT",
        url: `${baseUrl}/user/profile/${profileId}?site=${internalName}`,
        headers: contentApiHeaders,
        data: {
          metadata: { ...metadata },
        },
      };
    }
    try {
      await axios(obj);
      successHandler();
      response = { status: "success", message: "" };
    } catch (error) {
      if (typeof error === "string") {
        errorHandler(error);
      } else {
        errorHandler(get(error, "response.data.error", "Unable to process"));
      }
    }
    return;
  });
};
  