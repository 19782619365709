import React from "react";
import {  applyModuleData } from "~/Modules";
import { getToken } from "~/redux/user/tokens";
import { axiosWithKey } from "~/axios";
import jwtDecode from 'jwt-decode'
import { userLanguageSelector } from "~/tools/localization";
import debounce from "lodash/debounce";
import Spinner from '~/components/Spinner';
import {getUserState} from '~/redux/user/helpers'

class LazyLoadModules extends React.Component {
	constructor(props) {
		super(props);
		this.moreToLoad = true;
		this.offset = this.props.offset || 4;
		this.defaultModuleLimit = this.props.defaultModuleLimit || 4;
		this.handleScroll = this.handleScroll.bind(this);
		this.activateScroll = true
		this.scrollOffset = 0
		this.state = {
			modules: this.props.modules,
			layouts: this.props.layouts,
			page: this.props.page,
			newModules: [],
			items: [],
			initialHeight: null,
			hasFetched: false,
			fetching: false,
			pageReload : this.props.pageReload
		};
	}

	componentDidMount() {
		window.addEventListener("wheel", debounce(this.handleScroll, 50), {
			passive: false
		});
		window.addEventListener("touchmove", debounce(this.handleScroll, 50), {
			passive: false
		});
		window.addEventListener("scroll", debounce(this.handleScroll, 50), {
			passive: false
		});		
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.pageReload) {
			this.loadData();
		}
	}

	hasContentReachedBottom() {
		let wrapper = (this.wrapper && this.wrapper.clientHeight) || 0;
		let body = this.state.initialHeight;
		return body - wrapper < 0;
	}

	handleScroll = event => {
		event && event.preventDefault();
		if (
			this.isBottomOfPage() &&
			this.moreToLoad === true &&
			this.state.fetching === false &&
			this.state.page.modulesCount &&
			!this.state.hasFetched
		) {
			if(this.scrollOffset !== this.offset && this.activateScroll) {
				this.loadData();
			}		
		}
	};

	isBottomOfPage() {
		const windowHeight = window.innerHeight;
		const body = window.document.body;
		const html = window.document.documentElement;
		const docHeight = Math.max(
			body.scrollHeight,
			body.offsetHeight,
			html.clientHeight,
			html.scrollHeight,
			html.offsetHeight
		);
		const windowBottom = windowHeight + window.pageYOffset;
		return windowBottom >= (docHeight-150);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	loadData() {
		if (
			window &&
			window.settings &&
			window.settings.features &&
			window.settings.features.defaultModuleLimit
		) {
			this.defaultModuleLimit = window.settings.features.defaultModuleLimit;
			if(!this.props.offset) {
				this.offset = this.defaultModuleLimit
			}
		}
		let modules = this.state.page.modules;
		var pageData = { ...this.state.page };
		if (
			this.state.items === undefined &&
			this.state.modulesLayout === undefined
		)
			return;
		if (
			this.state.fetching === false &&
			this.moreToLoad === true &&
			this.offset < this.state.page.modulesCount
		) {
			this.activateScroll = false
			this.setState({fetching: true}, () => {
				let route = window.location.pathname;
				var requestHost = window.location.hostname
				var domainNames = window.settings.domainNames
				if(!domainNames.includes(requestHost)) {
					var originPathElement = document.getElementById('origin-path');
					var originPath = null;
					if(originPathElement) {
						originPath = originPathElement.getAttribute('data-seo');
					}
					if(originPath && route !== originPath) {
						route = originPath
					}
				}
				if (route.length > 1) route = route.replace(/\/$/, "");
				let baseUrl = ""
				if(window.settings.apiBaseUrl === "https://prod-api.viewlift.com") {
					baseUrl = "https://prod-api-cached-2.viewlift.com"
				} else {
					baseUrl = window.settings.apiBaseUrlCached || window.settings.apiBaseUrl
				}
	
				getToken(token => {
					var decodedToken = jwtDecode(token);
					var tokenCountryCode = decodedToken.countryCode
					var contentApiHeaders = {
						Authorization: token,
						'x-api-key': window.settings.baseAPIKey,
					}
					axiosWithKey({
						method: "GET",
						url: `${baseUrl}/content/pages`,
						headers: contentApiHeaders,
						params: {
							path: route,
							site: window.settings.internalName,
							includeContent: true,
							moduleOffset: this.offset,
							moduleLimit: this.defaultModuleLimit,
							languageCode : userLanguageSelector(),
							countryCode: tokenCountryCode,
							userState: getUserState()

						  }
					})
						.then(res => {
							window.removeEventListener("scroll", this.handleScroll);
							let items = modules;
							//if (res.data.modules.length > 0 && res.data.modules.length < 5) {
								pageData.modules = items
									? items.concat(res.data.modules)
									: res.data.modules;
								this.setState(
									{
										items: items
											? items.concat(res.data.modules)
											: res.data.modules,
										newModules: res.data.modules,
										hasFetched: true,
										fetching: false
									},
									() => {
										this.moreToLoad = true;
										this.offset = this.offset + this.defaultModuleLimit;
										this.scrollOffset = this.offset
										this.activateScroll = true
									}
								);
							// } else {
							// 	this.moreToLoad = false;
							// 	return;
							// }
						})
						.catch(err => {
							this.moreToLoad = false;
							return;
						});
				});
			})
		}
	}

	renderModules(layouts, modules) {
		/*
            This nestTabModules conditional was added because the Template Builder does not currently
            have a way to support nesting. This is basically a hacky workaround that splits up the modules
            and nests them for pages that have a Tabs Module.
        */
		// let tabModule = layouts.filter(moduleLayout => {
		// 	if (!moduleLayout.view) {
		// 		return false;
		// 	}
		// 	return moduleLayout.view.includes("Tabs");
		// });

		// if (tabModule.length > 0) {
		// 	return nestTabModules(layouts, modules, tabModule);
		// } else {
		// 	return applyModuleData(layouts, modules);
		// }
		return applyModuleData(layouts, modules);
	}
	render() {
		const spinnerCSS = {
			"margin": "-10px auto 0px auto"
		  }
		return (
			<React.Fragment>
				{this.state.fetching && <div className="module" style={spinnerCSS}><Spinner /> </div>}
				{this.state.newModules.length > 0 ? (
                    this.renderModules(this.props.layouts, this.state.newModules)
				) : (
					<div></div>
				)}
                {this.state.hasFetched && <LazyLoadModules layouts={this.props.layouts} page={this.state.page} offset={this.offset + this.defaultModuleLimit}/>}
			</React.Fragment>
		);
	}
}

export default LazyLoadModules;
