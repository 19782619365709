import Cookies from "js-cookie";
import get from 'lodash/get';

export function getLocalizedVal(data, parentPath, path, gistLanguageCode) {
    const messages = get(data, parentPath);
    var chosenLangCode = Cookies.get('userLanguageSelection');
    if (chosenLangCode === "" && window.settings.languages) {
        chosenLangCode = window.settings.languages.default.code
    } else if(gistLanguageCode && (chosenLangCode === "" || chosenLangCode === undefined)) {
        chosenLangCode = gistLanguageCode 
    }
    const localizedVal = get(messages, `localizationMap.${chosenLangCode}.${path}`)
    const defaultVal = get(messages, path)
    return localizedVal || defaultVal
}