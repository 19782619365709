import get from 'lodash/get';
import userLanguageSelector from './userLanguageSelector';
// input: primaryCTA, output: localized banner Text
export default primaryCta =>
  get(
    window,
    `settings.navigation.settings.localizationMap.${
      userLanguageSelector()
    }.primaryCta.bannerText`
  ) || primaryCta.bannerText;
