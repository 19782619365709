import TYPES from "./constants";

export var addItem = (payload) => {
  return {
    type: TYPES.ADD_ITEM,
    payload: payload,
  };
};

export var removeItem = (payload) => {
  return {
    type: TYPES.REMOVE_ITEM,
    payload: payload,
  };
};
