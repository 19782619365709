import React from 'react';

export function capitalize(variable) {
	return variable.replace(/^\w/, c => c.toUpperCase());
}

export const getOrdinal = (n) =>  {
	var s=["th","st","nd","rd"],
		v=n%100;
	return n+(s[(v-20)%10]||s[v]||s[0]);
 }

/**
 * Get feature value from window.settings, or use the default value if applied
 * @param {String} featureName name of feature
 * @param {String} defaultValue default value of feature
 */
export const getFeature = (featureName, defaultValue = null) => {
	if(featureName === undefined) return undefined;
	let featureValue = window.settings.features[featureName];
	featureValue = featureValue ? featureValue : (defaultValue ? defaultValue : undefined);
	return featureValue;
}

/**
 * Get metadata value from video's additional metadata, or use the default value if applied
 * @param {Object} video video object
 * @param {String} metadataName name of metadata
 * @param {String} defaultValue default value of metadata
 */
export const getVideoMetadataValue = (video, metadataName, defaultValue = null) => {
	if(!video || !video.gist || !video.gist.metadata) return undefined;
	let metadataValue = video.gist.metadata.filter(e => e.name === metadataName);
	return metadataValue.length === 1 ? metadataValue[0].value : (defaultValue ? defaultValue : undefined);
}

export const applyListItemCustomStyle = (props) => {
    if(!props.layout.settings || !props.layout.settings.enableCustomStyle) return;
    let {  progressBarColor:pbc, playIconColor:pic } = props.layout.settings;
    return <style dangerouslySetInnerHTML={{__html: `
		.appcms .list .ion-ios-play{
			color: rgba(${pic.r},${pic.g},${pic.b},${pic.a});
		}
		.appcms .list progress {
			color: rgba(${pbc.r},${pbc.g},${pbc.b},${pbc.a});
		}
    `}} />
}

export const cipher = salt => {
    const stringToCharacter = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const useSaltOnCharacter = code => stringToCharacter(salt).reduce((a,b) => a ^ b, code);
    return text => text.split('')
        .map(stringToCharacter)
        .map(useSaltOnCharacter)
        .map(byteHex)
        .join('');
}

/**
 * Move an array element from any array position to 1st position. 
 */
export const arrayMove = (array, selectedID) => {
    array.forEach(function(item, index){
      if(item && item.id === selectedID){
		array.splice(index, 1);
		array.unshift(item);
      }
    });
    return array;
}

/**
 * Get Page Route/URL
 */
export const getRoute = () => {
    let route = window.location.pathname
    var requestHost = window.location.hostname
    var domainNames = window.settings.domainNames
		if(!domainNames.includes(requestHost)) {
			var originPathElement = document.getElementById('origin-path');
			var originPath = null;
			if(originPathElement) {
				originPath = originPathElement.getAttribute('data-seo');
			}
			if(originPath && route !== originPath) {
				route = originPath
			}
		}
    if (route.length > 1) route = route.replace(/\/$/, '')
    return route;
}

// Converting YYYY-MM-DD to unix timestamp in second
export const getUnixTimestamp = (date) => {
	if(date) {
		return new Date(date).getTime() / 1000
	} else {
		return null
	}
}