import Cookies from 'js-cookie'
import store from '~/redux/user/store'

export const signupOrSignIn = () => {
  const isUserSignUp = Cookies.get('isUserSignUp') ? Cookies.get('isUserSignUp') : '';

  if(isUserSignUp) {
    // 1. check user signup
   return 'Signup';
    
  } else {
    // 2. check user login
    const user = store.getState().user;
    
    if(user && user.userId) {
        return 'Login';
    } else {
        return '';
    }
  }
}

export const redirectToPageIfNoPlans = () => {
  if(sessionStorage.getItem('noPlanAvailable') && (window.location.pathname == '/viewplans')){
    const redirectUrlIfNoPlansAvailable = window.settings && window.settings.navigation && window.settings.navigation.settings.primaryCta && window.settings.navigation.settings.primaryCta.redirectUrlIfNoPlansAvailable
    
    window.location.href = redirectUrlIfNoPlansAvailable
    return true
  }
}
