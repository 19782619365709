import  './styles.css'

export default function initializeSkin(id) {
	if(!window.settings.skinAdTag || window.SERVER_SIDE_RENDERING) {
		return
	}
	addSkin(id)
}

function load(url) {
	return new Promise(function(resolve, reject) {
		var script = window.document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.src = url;
		script.onload = resolve;
		script.onerror = reject;
		window.document.head.appendChild(script);
	})
}

function addSkin(id){
	load('https://www.googletagservices.com/tag/js/gpt.js').then(()=>{
		const googletag = window.googletag || {};
		googletag.cmd = googletag.cmd || [];
		googletag.cmd.push(() => {
			var skinOOP = googletag.defineOutOfPageSlot( window.settings.skinAdTag, 'viewlift-site-skin');
			skinOOP.addService(googletag.pubads());
			skinOOP.setTargeting("Site", "MSE");
			skinOOP.setTargeting("ID", id);
			googletag.pubads().enableSingleRequest();
			googletag.enableServices();
		});
		googletag.cmd.push(() => {
			googletag.display('viewlift-site-skin');
		});
	})
	window.addEventListener('message', receiveMessage)
}



function receiveMessage(event) {
	if(typeof event.data === "string" && event.data.includes('viewlift|site-skin:parameters')) {
		const clickLeft  = window.document.createElement('a');
		const clickRight  = window.document.createElement('a');
		clickLeft.classList.add('site-skin-click-area','site-skin-click-area-left');
		clickRight.classList.add('site-skin-click-area','site-skin-click-area-right');
		let data = event.data.split("|");
		data = JSON.parse(data[2]);
		const image = data.image;
		const url = data.url;
		window.document.getElementById('viewlift-site-skin').style.backgroundImage = `url(${image})`;
		if(url) {
			clickRight.href = url;
			clickRight.target = data.target
			clickLeft.href = url;
			clickLeft.target = data.target
			window.document.body.append(clickLeft, clickRight);
		}
	}
}
