import axios from 'axios'
import store from './store'
import { logout as _logout, getMySubscription, refresh, update, updateLatestTransaction, cancelSubscribe} from './actions'
import { cancelMySubscription, upgradeMySubscription, updatePayment } from './subscription'
import { getToken } from './tokens'
import Cookies from 'js-cookie';
import { getUserStateForAnonymousUser, getUserStateForExistingUser } from '~/tools/contentFilterFunctions'
import {trackFbEvent, FB_EVENTS} from '~/tools/analytics/fbPixel';
import { decodeAuthToken } from '~/redux/user/tokens';
import { initCleverTap } from '~/tools/analytics/cleverTap'
import initializeGtm from '~/tools/analytics/gtm'
import initFbEvent from '~/tools/analytics/fbEvent'
import { initGA4 } from "~/tools/analytics/gtmTagsGA4"

export function onUserAuth(cb) {
  var state = store.getState()
  var user = state.user

  store.subscribe(_ => {
    var newUser = store.getState().user
    if ((user && user.userId) === (newUser && newUser.userId))
      return

    user = newUser
    cb(user)
  })

  cb(user)
}

export function onUser(cb) {
  store.subscribe(_ => {
    cb(store.getState().user)
  })
  cb(store.getState().user)
}

export function refreshUser() {
  var state = store.getState()
  var user = state.user
  if (user && user.userId)
    return store.dispatch(refresh())
  return Promise.resolve()
}

export function getUser(cb) {
  var state = store.getState()
  if (cb) cb(state.user)
  return Promise.resolve(state.user)
}

export function updateUser(user) {
  return store.dispatch(update(user))
}

export function updateUserCard(user){
  return store.dispatch({ type: 'USER_CARD_DETAILS', payload: user })
}

export async function logout() {
  await trackFbEvent(FB_EVENTS.LOGOUT);
  return store.dispatch(_logout())
}

export function fetchUserSubscription(cb) {
  return store.dispatch(getMySubscription(cb))
}

export function cancelSubscription(getSelectedReason) {
  return cancelMySubscription(getSelectedReason).then( () => {
    setTimeout( _ => { fetchUserSubscription() }, 2000)
  })
}

export function upgradeSubscription(plan, paymentHandler,offerCode) {
  return upgradeMySubscription(plan, paymentHandler,offerCode).then(() => {
    setTimeout( _ => { fetchUserSubscription() }, 2000)
  })
}

export function updateStripePayment(stripeObject) {
  return updatePayment(stripeObject).then(() => {
    setTimeout( _ => { fetchUserSubscription() }, 2000)
  })
}

export function changePassword(data) {
  return getToken().then(token =>{
    return axios({
      url: '/identity/password',
      baseURL: window.settings.apiBaseUrl,
      method: 'POST',
      params: { 'site': window.settings.internalName },
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'x-api-key': window.settings.baseAPIKey
      },
      data: JSON.stringify(data)
    })
  }).then(res => {
    return res.data
  })
}

export function forgotPassword(value) {
  return axios({
    method: 'POST',
    baseURL: window.settings.apiBaseUrl,
    url: '/identity/password/forgot',
    params: { site: window.settings.internalName },
    data: { email: value },
    headers: {
      'x-api-key': window.settings.baseAPIKey
    }
  })
}

export function updateTransaction(details) {
  return store.dispatch(updateLatestTransaction(details))
}

// Subscription Notification Websocket
export const subscribeNotificationWs = (subscribeObj = {}) => {
  getToken(token => {
    const { onOpen, onMessageSuccess, onMessageFailure, onError } = subscribeObj;
    const webSocketBaseUrl = window.settings.apiBaseUrl && window.settings.apiBaseUrl.replace('api','ws').replace('https','wss');
    var paymentSocket = new WebSocket(webSocketBaseUrl + "/notification?token="+ token)
    var isPaymentinProcess = false;
    var subscribeMessage =  {
      "action": "subscribe",
      "eventType": "SUBSCRIPTION",
      "token" : token
    }

    paymentSocket.onopen = function() {
      console.log('socket opened')
      paymentSocket.send(JSON.stringify(subscribeMessage));
      setTimeout(()=>{
        if(!isPaymentinProcess){
          paymentSocket.close();
          typeof onOpen === 'function' && onOpen();
          cancelSubscribe();
          isPaymentinProcess = true;
          Cookies.set('isPaymentinProcess', isPaymentinProcess, { expires: 1 });
        }
      },60000);
    }
    paymentSocket.onmessage = function (event) {
      console.log("socket message received");
      isPaymentinProcess = true;
      var paymentData = event.data && JSON.parse(event.data)
      if(paymentData.event === "subscription" && paymentData.status === "completed") {
        typeof onMessageSuccess === 'function' && onMessageSuccess();
        isPaymentinProcess = false;
      } else {
        typeof onMessageFailure === 'function' && onMessageFailure();
        cancelSubscribe();
        isPaymentinProcess = false;
      }
      sessionStorage.removeItem("sessionFlag");
      Cookies.set('isPaymentinProcess', isPaymentinProcess, { expires: 1 });
      paymentSocket.close();
    }
    paymentSocket.onerror = function () {
      console.log("connection error");
      isPaymentinProcess = true;
      Cookies.set('isPaymentinProcess', isPaymentinProcess, { expires: 1 });
      paymentSocket.close();
      typeof onError === 'function'&& onError();
      // ref.props.success(null, "InProcess");
      cancelSubscribe();
    }
  })
}
export const flushAllProviders = () => {
  return getToken().then(token =>{
    return axios({
      method: 'POST',
      url: `${window.settings.apiBaseUrl}/identity/tveSignout`,
      params: { site: window.settings.internalName },
      headers: { 
        'x-api-key': window.settings.baseAPIKey,
        'Authorization':token
      },
      data: {}
    })
  })
}

export const getUserState = () => {
  const user = store.getState().user
  let defaultState;

  if(user && user.userId) {
    defaultState = getUserStateForExistingUser();
    const isMonetizationModelEnabled = window.settings.isMonetizationModelEnabled
    if(!isMonetizationModelEnabled)
      return null
    
  } else { // For Anonymous user
    defaultState = getUserStateForAnonymousUser();
  }
  return defaultState || (user && user.userState) || window.btoa('loggedOut'); 
}

export const getAnonymousUserId = () => {
    const authToken = decodeAuthToken();
    const anonymousUserId = (authToken && authToken.userId) || 'anonymous'
    return anonymousUserId
}

export const initOptionalCC = () => {
  /**
   * Currently Supportive CC are:
   * 1.Clevertap
   * 2.FB Events
   * 3.GA4
   * 4.Google Tag Manager(GTM)
   */

  const hideVLConsent = (window.settings.privacy && window.settings.privacy.vlCookiesConsent && window.settings.privacy.vlCookiesConsent.hideVLCookiesConsent) || null;
  const currentPath = window && window.location.pathname
  if(hideVLConsent || (currentPath === "/embed/player")){
    initCleverTap()
    initializeGtm()
    initFbEvent()
    initGA4()
  } else {
    if (Cookies.get("cc_cookie")){
      const getCookie = JSON.parse(Cookies.get("cc_cookie"))
      // Targeting Cookies(optional) category will control GTM, FB and Clevertap loading
      if(getCookie.level.includes("optional")){
        initCleverTap()
        initializeGtm()
        initFbEvent()
      }

      // Performance Cookies category will control GA loading.
      if(getCookie.level.includes("performanceCookie")){
        initGA4()
      }
    }
  }
}