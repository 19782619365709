


/*
    Manages and exposes the GTM datalayer object
*/

export const pushToDatalayer = (key,value) => {
    if(window.dataLayer)
    {
        let newValue  = {}
        newValue[key] = value;

        window.dataLayer.push(newValue);
    }
}


 export const checkForExists = (dataLayer, key) => {
    return dataLayer.some((item) => {
        return item[key];
    });
 }

 export const replaceKey = (dataLayer,key,value) => {
    return dataLayer.map((item) => {
        if(item[key])
        {
            item[key] = value;
        }
        return item;
    })
 }

 export const replaceByEvent = (dataLayer,value) => {
    
    dataLayer = dataLayer.filter((item) => {
        return item.event !== value.event;
    })
    
    return dataLayer.push(value);
 }