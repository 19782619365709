import userStore from '~/redux/user/store'
import { getSubscriptionPlans } from '~/redux/user/subscription'


export const getSvodRedirect = () => {
    const user = userStore.getState().user
    return user && user.redirectUrl
}

export const redirectLocation = () => {
    if(window.location.href.includes('embed/player')){
      window.location.reload();
    } else {
      window.location.href = window.location.pathname
    }
}

export const redirectToPageIfNoPlans = async () => {
  const userInfo = userStore.getState().user || {};
  const primaryCtaNav =  window.settings && window.settings.navigation && window.settings.navigation.settings.primaryCta && window.settings.navigation.settings.primaryCta
  const redirectToPageIfNoPlans = (primaryCtaNav && primaryCtaNav.redirectToPageIfNoPlans) || null
  const redirectUrlIfNoPlansAvailable = primaryCtaNav && primaryCtaNav.redirectUrlIfNoPlansAvailable

  if((redirectToPageIfNoPlans && userInfo.userId && !(userInfo.isSubscribed))){
    await getSubscriptionPlans().then(plans => {
      if(plans && plans.length < 1){
        sessionStorage.setItem('skipPlanPage', true);
        sessionStorage.setItem('noPlanAvailable', true)
        window.location.href = redirectUrlIfNoPlansAvailable
        return false
      }
    })
  }
}