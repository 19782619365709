import React from 'react'
import get from 'lodash/get'
import enhanceWithClickOutside from 'react-click-outside'

import connect from '~/tools/connect'
import share from '~/tools/share'
import  store from './redux'

import { getUser } from '~/redux/user/helpers'
import { getVideoInfo, addToQueue, removeFromQueue } from '~/redux/uservideo/helpers'

import { login } from '~/views/Popup/popup'
import { getLocalizedVal } from '~/tools/localization/helpers';

import X from '~/assets/images/X.svg'
import './styles.css'

class Share extends React.Component {
	constructor(){
		super()

    this.shareFacebook = this.shareFacebook.bind(this)
    this.shareTwitter = this.shareTwitter.bind(this)
    this.removeFromQueue = this.removeFromQueue.bind(this)
    this.handleAddToWatchlist = this.handleAddToWatchlist.bind(this)

		this.state = {
			open: true
		}
	}

	componentDidMount() {
		['resize', 'scroll'].map((e) => {
  			return window.addEventListener(e, this.hide.bind(this))
		});
	}

	componentDidUpdate(prevProps, prevState){
		let idCheck = !(prevState.args === undefined)
      ? (prevState.args.data.id !== this.state.args.data.id)
      : (!prevState.args && this.state.args)
		if (idCheck)
			this.fetchUserData(this.state.args.data.id)
	}

	fetchUserData(id) {
		getUser(user => {
			if (!user) {
				this.setState({ userData: null })
				return
			}
			getVideoInfo(id, data => {
				this.setState({ userData: data })
			})
		})
	}

	hide() {
		this.state.open && this.setState({ open: false })
	}

	handleClickOutside(e) {
		var targetClass = e.target.classList
		if (targetClass.contains('share-toggle__icon') || targetClass.contains('small-rect')) {
	 		this.setState({ open: true })
		} else {
      this.setState({ open: false })
    }
	}

	addToQueue() {
    let videoInfo = (this.state && this.state.args && this.state.args.data.title) || this.state.args.data.id
		addToQueue( this.state.args.data.id, this.state.args.data.contentType, videoInfo, data => {
			this.setState({ userData: data })
		})
	}

	removeFromQueue() {
    let videoInfo = (this.state && this.state.args && this.state.args.data.title) || this.state.args.data.id
		removeFromQueue(this.state.args.data.id, videoInfo, data => {
			this.setState({ userData: data })
		})
	}

  get numOfShareOptions() {
    let num = 3
    const options = this.state.args.options
    if (!options)
      return num

    if (options.watchlist === false)
      num--
    if (options.facebook === false)
      num--
    if (options.twitter === false)
      num--

    return num
  }

  get divPos() {
    const pos = get(this.state, 'args.position')
    const isOverflowed = this.isOverflowed()
    const numShareOptions = this.numOfShareOptions

    return {
      left: pos.left - (isOverflowed ? (210/2) : 210),
      top: pos.top - document.body.getBoundingClientRect().top - numShareOptions * (isOverflowed ? 40 : 15),
      position: 'absolute'
    }
  }

  shareFacebook() {
    const { args } = this.state
    share.facebook(window.location.origin + args.data.permalink)
  }

  shareTwitter() {
    const { args } = this.state
    share.twitter(window.location.origin + args.data.permalink, args.data.title)
  }

  handleAddToWatchlist() {
    get(this.state, 'userData.contentId')
      ? this.addToQueue()
      : login()
  }

  get shareToggleClassNames() {
    return `share-toggle share-toggle--${ this.state.open ? 'open' : 'closed' }`
  }

  renderQueueBtns() {
    if (get(this.state,'args.options.watchlist') === false)
      return

    return ((this.state.userData && this.state.userData.isQueued)
      ? <div
          className="share-option"
          onClick={ this.removeFromQueue }>
            <span className="ion-minus-round icon"></span>
            <span className="share-text">{getLocalizedVal(window.settings,'genericMessages','removeFromWatchlist') && getLocalizedVal(window.settings,'genericMessages','removeFromWatchlist').toLowerCase()}</span>
        </div>
      : <div
          className="share-option"
          onClick={ this.handleAddToWatchlist }>
          <span className="ion-plus-round icon"></span>
          <span className="share-text">{getLocalizedVal(window.settings,'genericMessages','addToWatchlist') && getLocalizedVal(window.settings,'genericMessages','addToWatchlist').toLowerCase()}</span>
        </div>
    )
  }

  isOverflowed() {
    return get(this.state, 'args.position.left') < 220
  }

	render() {
		if (!this.state.args)
  		return <div/>

  	return(
			<div
        className={ this.shareToggleClassNames }
        style={ this.divPos }>
				<div
          className={`share-toggle__options `+ (this.isOverflowed() && "overflow")}>
					{this.state && this.state.args && this.state.args.data && this.state.args.data.contentType !== "BUNDLE" && <div className="queue-button">
            { this.renderQueueBtns() }
					</div>}
					<div className="fb-share share-option" onClick={ this.shareFacebook }>
            <span className="ion-social-facebook icon"></span>
            <span className="share-text">{getLocalizedVal(window.settings,'genericMessages','shareFacebook') && getLocalizedVal(window.settings,'genericMessages','shareFacebook').toLowerCase()}</span>
          </div>
					<div className="twitter-share share-option" onClick={ this.shareTwitter }>
            <span>
            <i>
                <img src={X} alt="X-Icon" className="logo-x-icon"/>
              </i>
            </span>
            <span className="share-text">{getLocalizedVal(window.settings,'genericMessages','shareTwitter') && getLocalizedVal(window.settings,'genericMessages','shareTwitter').toLowerCase()}</span>
          </div>
				</div>
			</div>
		)
	}
}

connect(Share, (state) => ({ show: state.show, args: state.args }), store)

export default enhanceWithClickOutside(Share);
