import { getToken } from '../user/tokens'
import { getUser, getUserState } from '../user/helpers'
import { userLanguageSelector } from '~/tools/localization'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { checkGAVersion, trackECEvent } from '~/tools/analytics/gtmTagsGA4';
var cache = {}

export function getVideoInfo(videoId, cb) {
  if (cache[videoId])
    return cb(cache[videoId])

  getToken(token => {
    if (!token) {
      console.error('No user token')
      return
    }

    axios({
      method: 'GET',
      url: `${window.settings.apiBaseUrl}/user/info/video/${videoId}`,
      params: { site: window.settings.internalName },
      headers: {
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey
      }
    }).then(res => {
      cache[videoId] = res.data
      cb(cache[videoId])
    }).catch(err => {
      console.error(err)
    })
  })
}

export function getVideoInfoNews(videoId, cb) {

  getToken(token => {
    if (!token) {
      console.error('No user token')
      return
    }

    axios({
      method: 'GET',
      url: `${window.settings.apiBaseUrl}/user/info/video/${videoId}`,
      params: { site: window.settings.internalName },
      headers: {
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey
      }
    }).then(res => {
      cache[videoId] = res.data
      cb(cache[videoId])
    }).catch(err => {
      console.error(err)
    })
  })
}

export function getVideoData(videoId, cb) {
  getToken(token => {
  var decodedToken = jwtDecode(token);
	var tokenCountryCode = decodedToken.countryCode
  let baseUrl = ""
	if(window.settings.apiBaseUrl === "https://prod-api.viewlift.com") {
		baseUrl = "https://prod-api-cached-2.viewlift.com"
	} else {
		baseUrl = window.settings.apiBaseUrl
	}
  const languageCode = window.userLanguageSelection || userLanguageSelector()
  axios
    .get(`${baseUrl}/content/videos/` + videoId, {
      params: {
        site: window.settings.internalName,
        countryCode: tokenCountryCode,
        ...(languageCode ? { languageCode } : {})
      },
      headers: {
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey
      },
      contentType: 'application/json',
    }).then(res => {
      cache[videoId] = res.data
      cb(cache[videoId])
    }).catch(err => {
      console.error(err)
    })
  })
}

export function addToQueue(videoId, contentType, videoInfo, cb, cbCatch) {
  getUser(user => {
    getToken(token => {
      axios({
        method: "POST",
        url: `${window.settings.apiBaseUrl}/user/queues`,
        params: { site: window.settings.internalName },
        headers: {
          Authorization: token,
          "x-api-key": window.settings.baseAPIKey
        },
        data: {
          userId: user.userId,
          contentId: videoId,
          position: 1,
          contentType: contentType
        }
      })
        .then(res => {
          if (cache[videoId]) cache[videoId].isQueued = true;

          if(checkGAVersion('GA4')){
            trackECEvent('Added to Watchlist', 'Watchlist', videoInfo)
          }
          if(checkGAVersion('GA')){
            var ga = window.ga
            if (ga) { 
              ga('send', {
                hitType: 'event',
                eventCategory: 'Watchlist',
                eventAction: 'Added to Watchlist',
                eventLabel: videoInfo
              });
            }
          }
          
          getVideoInfo(videoId, cb);
        })
        .catch(err => {
          cbCatch && cbCatch();
          console.error(err);
        });
    })
  })
}

export async function playVideo(userId, videoId) {
  let token = await getToken();
  return axios({
    method: 'PUT',
    url: `${window.settings.apiBaseUrl}/transaction/changeStatus`,
    params: { userId: userId, videoId: videoId },
    headers: {
      Authorization: token,
      'x-api-key': window.settings.baseAPIKey
    },
  }).catch(err => {
    console.error(err)
  })
}

export function removeFromQueue(videoId, videoInfo, cb, cbCatch) {
  getUser(user => {
    getToken(token => {
      axios({
        method: 'DELETE',
        url: `${window.settings.apiBaseUrl}/user/queues`,
        params: { site: window.settings.internalName, userId: user.userId, 'contentIds': videoId },
        headers: {
          Authorization: token,
          'x-api-key': window.settings.baseAPIKey
        },
      }).then(res => {
        // cache[videoId] = null
        if (cache[videoId])
          cache[videoId].isQueued = false
          
          if(checkGAVersion('GA4')){
            trackECEvent('Removed from Watchlist', 'Watchlist', videoInfo)
          }
          if(checkGAVersion('GA')){
            var ga = window.ga
            if (ga) { 
              ga('send', {
                hitType: 'event',
                eventCategory: 'Watchlist',
                eventAction: 'Removed from Watchlist',
                eventLabel: videoInfo
              });
            }
          }

        getVideoInfo(videoId, cb)
      }).catch(err => {
        cbCatch();
        console.error(err)
      })
    })
  })
}

export function deleteHistory(videoIds) {
  return getUser().then(user => {
    return getToken().then(token => {
      return axios({
        method: 'DELETE',
        url: `${window.settings.apiBaseUrl}/user/userId/history/video`,
        params: {
          version: "v2",
          ...(videoIds && { 'videoIds': videoIds }),
          site: window.settings.internalName
        },
        headers: {
          'Authorization': token,
          'x-api-key': window.settings.baseAPIKey
        }
      }).catch(err => console.log(err))
    })
  })
}

export function fetchSeriesHistory(userId, seriesId) {
  return getToken()
  .then(token => {
    return axios.get(`${window.settings.apiBaseUrl}/user/userId/history/video`, {
      headers: {
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey,
      }, 
      params: {
        site: window.settings.internalName,
        version: "v2",
        seriesId: seriesId,
      }
    }).catch(err => console.log(err))
  })
}

export async function fetchRecommendations(userId, contentType, requestType) {
  return getToken().then(token => {
    var decodedToken = jwtDecode(token);
    var countryCode = decodedToken.countryCode
    const languageCode = window.userLanguageSelection || userLanguageSelector()
    return axios({
      method: "GET",
      url:`${window.settings.apiBaseUrlCached || window.settings.apiBaseUrl}/user/recommendations/getRecommendations?userId=${userId}`,
      params: {
        site: window.settings.internalName,
        countryCode,
        languageCode,
        requestType,
        contentType,
        userState:getUserState()
      },
      headers: {
        Authorization: token,
        "x-api-key": window.settings.baseAPIKey
      }
    })
    .then(x => {
      if(window.settings.recommendation && 
        window.settings.recommendation.recExcludedCountries && 
        window.settings.recommendation.recExcludedCountries.length>0 && 
        countryCode &&
        window.settings.recommendation.recExcludedCountries.includes(countryCode)){
          return [];
      }
      return (x && x.data && x.data.records) || [];
    });
  })
}

export function fetchHistory(userId, page) {
  let languageCode = userLanguageSelector()
  return getToken().then(token => {
    return axios({
      method: "GET",
      url: `${window.settings.apiBaseUrl}/user/userId/history/video`,
      headers: {
        Authorization: token,
        "x-api-key": window.settings.baseAPIKey
      },
      params: {
        site: window.settings.internalName,
        version: "v2",
        limit: 25,
        languageCode,
        ...(page && { 'page': page }),
        userState:getUserState()

      }
    }).then(x => (x && x.data && x.data.records) || []);
  })
}

export function getTransactionInfo(userId, contentMeta, ...options) {

  let apiUrl = window.settings.isMonetizationModelEnabled ? '/transaction/purchases/metadata' : '/transaction/getData'

  return getToken().then(token => {
    return axios({
      method: 'GET',
      url: `${window.settings.apiBaseUrl+apiUrl}`,
      params: {
        site: window.settings.internalName,
        userState:getUserState(),
        userId: userId,
        contentMeta: contentMeta,
        ...(options.length === 1 && { 'page': options[0], limit: 25 }),
        ...(options.length > 1 && { 'type': options[0], 'contentId': options[1] })
      },
      headers: {
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey
      }
    }).catch(err => console.log(err))
  })
}

export function saveWatchHistory(data) {
  return getToken().then(token => {
    return axios({
      url: '/user/userId/history/video',
      baseURL: window.settings.apiBaseUrl,
      method: 'POST',
      headers: {
        'Authorization': token,
        'x-api-key': window.settings.baseAPIKey
      },
      params: {
        version: "v2"
      },
      data: JSON.stringify(data)
    })
    .then(res => {
      return res.data;
    })
  }).catch(err=>{
    let error = err && err.response && err.response.data && err.response.data.errorCode
    let errorMessage = err && err.response && err.response.data && err.response.data.errorMessage
    let errorData = {
      error: error,
      errorMessage: errorMessage
    }
    return errorData
  })
}

export function getLikedStatus(userId, contentId){
  return getToken().then(token => {
    return axios({
      url: '/user/likes',
      baseURL: window.settings.apiBaseUrl,
      method: 'GET',
      params:{
        userId: userId,
        site: window.settings.internalName,
        contentId: contentId
      },
      headers: {
        'Authorization': token
      }
    })
    .then(res =>{
      return res.data;
    })
  }).catch(err=>{console.log(err)})
}

export function getLikedCount(contentId){
  return getToken().then(token => {
    return axios({
      url: `/user/count/likes/${contentId}`,
      baseURL: window.settings.apiBaseUrl,
      method: 'GET',
      params: {
        site: window.settings.internalName
      },
      headers: {
        'Authorization': token,
        'x-api-key': window.settings.baseAPIKey
      }
    })
    .then(res => {
      return res.data;
    })
  })
  .catch(err=>{console.log(err)})
}

export function likeAVideo(userId, contentType, contentId, title){
  return getToken().then(token => {
    return axios({
      url: '/user/likes',
      baseURL: window.settings.apiBaseUrl,
      method: 'POST',
      params: {
        userId: userId,
        site: window.settings.internalName
      },
      data: {
        contentId: contentId,
        contentType: contentType,
        title: title
      },
      headers: {
        'Authorization': token
      }
    })
    .then(res => {
      return res.data;
    })
  })
  .catch(err=>{console.log(err)})
}

export function dislikeAVideo(userId, contentId){
  return getToken().then(token => {
    return axios({
      url: `/user/likes/${contentId}`,
      baseURL: window.settings.apiBaseUrl,
      method: 'DELETE',
      params: {
        userId: userId,
        site: window.settings.internalName
      },
      headers: {
        'Authorization': token
      }
    })
    .then(res => {
      return res.data;
    })
  })
  .catch(err=>{console.log(err)})
}