import axios from 'axios'
import get from 'lodash/get';

const baseUrl = "https://idp.securetve.com/rest/1.0/";
const platformId = get(window.settings, 'tveSettings.platform_id')

export const initAuth = (idpId, redirect) => {
    let redirectUrl = redirect || 'default'
    const authApi = baseUrl+platformId+"/init/"+idpId+"?responsemethod=redirect&format=jsontext&responsetarget="+redirectUrl
    window.location.assign(authApi)
}

export const initBounce = () => {
    return axios.get(baseUrl+platformId+"/init/",{
      withCredentials:true
    })
    .then(res => {
      return res.data.authenticated;
    })
    .catch(err => {
      return false;
    })
}

export const initAuthZ = () => {
  const resource_ids = window.settings.tveSettings && window.settings.tveSettings.tve_provider === 'verimatrix' && window.settings.tveSettings.resource_id && window.settings.tveSettings.resource_id.split(',')
  if(!resource_ids)
    return null
  
  let params = ''
  for(let i = 0; i < resource_ids.length; i++){
    params += 'resource_id='+((resource_ids[i]).trim())+(i !== (resource_ids.length-1) ? '&' : '')
  }
  return axios.get(baseUrl+platformId+"/identity/resourceAccess/?"+params, {
    withCredentials:true
  })
  .then(res => {
    return res && res.data && res.data.documents
  })
  .catch(err => {
    console.log(err)
  })
}

export const initSlo = () => {
  return axios.post(baseUrl+platformId+"/slo/")
}

export const registerDevice = (shortcode) => {
    return axios.get(baseUrl+platformId+"/identity/registerDevice/"+shortcode, {
      withCredentials:true
    })
}
