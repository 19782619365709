import get from 'lodash/get';
import find from 'lodash/find';
import { updateNumber } from '~/views/Popup/popup';
import { axiosWithKey } from "~/axios"
import { getToken } from '~/redux/user/tokens'
import { userLanguageSelector } from '~/tools/localization'
import Cookies from 'js-cookie';


/**
 * When OTP is On hideCloseButtonOnAddPhoneNumber is On and we have to show Update phone Number screen anyway.
 */
export const isCloseButtonHide = () => {
    const userInfo = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : '';
    const userId = userInfo && userInfo.userId;
    const getQueryString = window.location.search;
   
    if(!userId || getQueryString || (window.location.pathname === '/juspay-payment')) {
        return false;
    }

    const userPhoneNumber = userInfo && userInfo.phoneNumber;
    const isOTPEnabled = get(window.settings, 'features.otp_value.otp_enabled');
    const manageProfile = get(window.settings, 'features.otp_value.manageProfile');
    const hideCloseButtonOnAddPhoneNumber = get(window.settings, 'features.otp_value.hideCloseButtonOnAddPhoneNumber');
    
    if(userId && (!userPhoneNumber || userPhoneNumber === "NA" || userPhoneNumber === undefined)) {
        if(isOTPEnabled && hideCloseButtonOnAddPhoneNumber) {
            return true;
        } else if((isOTPEnabled && manageProfile)) {
            const providerList = ['viewlift','facebook', 'google', 'apple'];
            const providerName = userInfo && userInfo.provider;
            const providerFlag = providerList.includes(providerName.toLowerCase());
            const displayAddPhoneScreenFlag = window.sessionStorage.getItem('displayAddPhoneScreen') || false;

            if(providerFlag && !displayAddPhoneScreenFlag) {
                window.sessionStorage.setItem('displayAddPhoneScreen', true)
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const displayPhoneNumberScreen = async () => {
    
    if(isCloseButtonHide()) {
        const authNavObj = find(window.settings.navigation.user, function (o) {return (o.displayedPath === "Authentication Screen");});
        const { internalName } = window.settings;
        const pagePath = authNavObj.url;
        let baseUrl = ""
        
        if(window.settings.apiBaseUrl === "https://prod-api.viewlift.com") {
            baseUrl = "https://prod-api-cached-2.viewlift.com"
        } else {
            baseUrl = window.settings.apiBaseUrlCached || window.settings.apiBaseUrl
        }

        return await getToken(token => { axiosWithKey({
            method: "GET",
            url: `${baseUrl}/content/pages?path=${pagePath}&site=${internalName}&languageCode=${userLanguageSelector()}&includeContent=true`,
            
            headers: { Authorization: token }
        })
        .then( response => {
                const responseMetadataMap =  response && response.data && response.data.modules[0] && response.data.modules[0].metadataMap;
            
                return updateNumber(responseMetadataMap);
            })
            .catch(error => {
                return error.response;
            });
        });
    }
} 