import { combineReducers } from "redux";
import defaultState from "./initialState";

const initialState = (window.initialStoreState && window.initialStoreState.page) || defaultState

const data = (state= initialState.data, action={}) => {
  switch (action.type) {
    case 'FETCH_PAGE_PENDING': {
      return {}
    }
    case 'FETCH_PAGE_FULFILLED': {
      return action.payload
    }
    case 'FETCH_PAGE': {
      return action.payload
    }
    case 'FETCH_PAGE_REJECTED': {
      const error = action.payload
      return { 
        error: {
          'status': error && error.status, 
          'statusText': error && error.statusText, 
          'response': error && error.response 
        }
      }
    }
    default: {
      return state
    }
  }
}

const fetchingPage = (state = initialState.fetchingPage, action={}) => {
  switch (action.type) {
    case 'FETCHING_PAGE': {
      return action.payload;
    }
    default:
      return state;
  }
}

const adobeStatus = (state = initialState.adobeStatus, action={}) => {
  switch (action.type) {
    case 'ADOBE_STATUS': {
      return action.payload;
    }
    default:
      return state;
  }
}

export default combineReducers({
  data,
  fetchingPage,
  adobeStatus
})

