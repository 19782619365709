import defaultState from "./initialState";
import TYPES from "./constants";
const initialState = sessionStorage.getItem("purchaseInProcess")
  ? JSON.parse(sessionStorage.getItem("purchaseInProcess"))
  : defaultState;

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.ADD_ITEM: {
      const resp = { ...state, ...action.payload };
      sessionStorage.setItem("purchaseInProcess", JSON.stringify(resp));
      return resp;
    }
    case TYPES.REMOVE_ITEM: {
      sessionStorage.setItem("purchaseInProcess", JSON.stringify(defaultState));
      return defaultState;
    }
    default: {
      return state;
    }
  }
};
