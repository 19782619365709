export function ellipsifyByWord(str, ellipsify, ifgreater) {
  str = str.split(' ')
  if (str.length > ifgreater) {
    return str.splice(0, ellipsify).join(' ') + '...'
  } else {
    return str.join(' ')
  }
}

export function ellipsifyByChar(str, limit) {
  if(!str) return '';
  if (str.length <= limit) return str
  return str.slice(0, limit) + '...'
}
