const satisfiChatId = (window.settings.notifications && window.settings.notifications.satisfiChatId) || null;
const satisfiChatDelayTime = (window.settings.notifications && window.settings.notifications.satisfiChatDelayTime) || 3; //Default loading delay is 3Sec.
const delayTime = satisfiChatDelayTime * 1000 // Changing into millisecond. 

const satisfiChatJS = () =>{
    const existingScript = document.getElementById('satisfiScript');
    if (!existingScript) {
        return new Promise (function(resolve, reject){
            if(satisfiChatId) {
                var script = document.createElement('script');
                script.type= 'text/javascript';
                script.defer = true;
                script.id = 'satisfiScript';
                script.src = 'https://chat.satis.fi/popup/embedder?popupId='+satisfiChatId;
                script.onload = resolve;
                script.onerror = () => {
                    reject('Cannot load SatisfiChat js')
                    document.head.removeChild(script);
                }
                document.head.appendChild(script);
            } else {
                resolve(null);
            }   
        })
    } else {
        return new Promise (function(resolve){
            resolve();
        })
    }
}

const loadSatisfiChatJS = () => {
    if(!satisfiChatId){
        return
    }
    setTimeout(()=> satisfiChatJS(), delayTime);
}
export default loadSatisfiChatJS;