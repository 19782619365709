import { applyMiddleware, createStore } from 'redux'

import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import reducer from './reducer'

var middlewareArray = [promise(), thunk]

var middleware = applyMiddleware(...middlewareArray)
var store = createStore(reducer, middleware)

export default store;