import get from 'lodash/get';
import userLanguageSelector from './userLanguageSelector';
// input: primaryCTA, output: localized Cta Text
export default primaryCta =>
  get(
    window,
    `settings.navigation.settings.localizationMap.${
      userLanguageSelector()
    }.primaryCta.ctaText`
  ) || primaryCta.ctaText;
