const delayTime = 20000; //Freshchat loading 20sec delay.

const loadJS = async () => {
    let customerService = window.settings.customerService
    let freshChat = customerService && customerService.freshChat
    let baseUrl = (freshChat && freshChat.url) || "https://wchat.freshchat.com"
    return await new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = baseUrl+'/js/widget.js';
        script.onload = resolve;
        script.onerror = () => {
            reject('Cannot load FreshChatBot js')
            document.head.removeChild(script);
        }
        document.head.appendChild(script);
    })
}

function initFreshChatBot() {
    const customerService = window.settings.customerService
    const freshChat = customerService && customerService.freshChat
    const token = freshChat && freshChat.hashKey
    const enableFreshChat = freshChat && freshChat.enableFreshChat
    let baseUrl = (freshChat && freshChat.url) || "https://wchat.freshchat.com"
    if (enableFreshChat && token) {
        loadJS()
        .then(
            function () {
                window.fcWidget.init({
                    token: token,
                    host: baseUrl
                });
            })
    }
}

function loadFreshChatBot(customDelay = NaN) {
    setTimeout((event)=>initFreshChatBot(event), customDelay || delayTime);
}
export default loadFreshChatBot;