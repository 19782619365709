import localizeTitle from './localizeTitle';
import userLanguageSelector from './userLanguageSelector';
import localizeCtaText from './localizeCtaText';
import localizeLoginCtaText from './localizeLoginCtaText';
import localizeLogoutCtaText from './localizeLogoutCtaText';
import localizeHelloText from './localizeHelloText';
import localizeBannerText from './localizeBannerText'
import localizeUrl from './localizeUrl'

export {
  localizeTitle,
  userLanguageSelector,
  localizeCtaText,
  localizeLoginCtaText,
  localizeLogoutCtaText,
  localizeHelloText,
  localizeBannerText,
  localizeUrl
};