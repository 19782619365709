import React, { useEffect } from "react";
import "./home.css"

const HomeSkeletonLoader = () => {
    
    useEffect(()=> {
        let root = document.documentElement
        let skeletonColor = window.settings.brand.general.skeletonColor
        skeletonColor && root.style.setProperty('--skeletonBackgroundColor', skeletonColor)
    },[])
    return (
        <div className="skeleton-container">
        <div className="style4">
        <div className="ssrmodule style8 style9">
        </div>
        <div className="ssrmodule style8">
        </div>
        <div className="ssrmodule style2"></div>
        <div className="module">
            <div className="video-tray portrait">
                <div className="tray-wrapper-common">
                    <div className="BlockTitle-ssr site-color style7"></div>
                </div>
                <div className="items">
                    <div className="slick-initialized slick-slider">
                        <div className="slick-list">
                            <div className="slick-track style3">
                                <div data-index="0" className="slick-slide-ssr slick-active style5" tabIndex="-1" >
                                    <div></div>
                                </div>
                                <div data-index="1" className="slick-slide-ssr slick-active style5" tabIndex="-1" >
                                    <div></div>
                                </div>
                                <div data-index="2" className="slick-slide-ssr slick-active style5" tabIndex="-1" >
                                    <div></div>
                                </div>
                                <div data-index="3" className="slick-slide-ssr slick-active style5" tabIndex="-1" >
                                    <div></div>
                                </div>
                                <div data-index="4" className="slick-slide-ssr slick-active style5" tabIndex="-1" >
                                    <div></div>
                                </div>
                                <div data-index="5" className="slick-slide-ssr slick-active style5" tabIndex="-1" >
                                    <div></div>
                                </div>
                                <div data-index="6" className="slick-slide-ssr slick-active style5" tabIndex="-1" >
                                    <div></div>
                                </div>
                                <div data-index="7" className="slick-slide-ssr slick-active style5" tabIndex="-1" >
                                    <div></div>
                                </div>
                                <div data-index="8" className="slick-slide-ssr style5" tabIndex="-1" >
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    )
}

export default HomeSkeletonLoader;