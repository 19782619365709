import extend from 'lodash/extend';
import Cookies from 'js-cookie'
import extract from '~/tools/extractDomain'

var storedUser = Cookies.get('user') && Cookies.get('user')
if(storedUser === "undefined" || storedUser === undefined) {
  storedUser = null
}

var thirdPartyRedirectData = Cookies.get('vl-user-data') ? JSON.parse(Cookies.get('vl-user-data')) : null
var parsedUser = (storedUser && JSON.parse(storedUser)) || (thirdPartyRedirectData && thirdPartyRedirectData.user)
var cookieUser = parsedUser && (parsedUser.authorizationToken && parsedUser.userId) ? true : false
var subscription = localStorage.getItem('subscription') && localStorage.getItem('subscription')
var parsedSubscription = subscription && JSON.parse(subscription)
var userInfo = (parsedUser && (Object.keys(parsedUser).length !== 0 && cookieUser)) ? parsedUser : null

var purchasedItems = localStorage.getItem('purchasedItems') && localStorage.getItem('purchasedItems')
var parsedPuchasedItems = (purchasedItems && JSON.parse(purchasedItems)) || null;

var initialState = ({ user: userInfo, subscription: (parsedSubscription || null),purchasedItems: parsedPuchasedItems })

export default function reducer(state=initialState, action={}) {
  var pl = action.payload
  var iosResponseKeyOne = (pl && pl.data && pl.data.subscription &&  pl.data.subscription.subscriptionInfo && pl.data.subscription.subscriptionInfo.iosResponse) ? true :false
  var receiptKeyOne = (pl && pl.data && pl.data.subscription &&  pl.data.subscription.subscriptionInfo && pl.data.subscription.subscriptionInfo.receipt) ? true :false
  if(iosResponseKeyOne){
    delete pl.data.subscription.subscriptionInfo["iosResponse"]
  }
  if(receiptKeyOne){
    delete pl.data.subscription.subscriptionInfo["receipt"]
  }
  var iosResponseKeyTwo = (pl && pl.subscriptionInfo &&  pl.subscriptionInfo.iosResponse)? true : false
  var receiptKeyTwo = (pl && pl.subscriptionInfo &&  pl.subscriptionInfo.receipt)? true : false
  if(iosResponseKeyTwo){
    delete pl.subscriptionInfo["iosResponse"]
  }
  if(receiptKeyTwo){
    delete pl.subscriptionInfo["receipt"]
  }
  var extention =
    action.type === 'LOGIN_FULFILLED' ? { user: pl } :
    action.type === 'SIGNUP_FULFILLED' ? { user: pl } :
    action.type === 'USER_CARD_DETAILS' ? {user: pl} :
    action.type === 'LOGOUT_FULFILLED' ? { user: null, subscription : null, purchasedItems: null } :
    action.type === 'UPDATE_FULFILLED' ? {
      user: {...state.user,
        email: pl.data.email,
        emailConsent: (pl.data.emailConsent === true || pl.data.emailConsent === false ) ? pl.data.emailConsent : state.user.emailConsent,
        name: pl.data.name,
        phoneNumber: pl.data.phoneNumber ? pl.data.phoneNumber : state.user.phoneNumber,
        passwordEnabled: pl.data.passwordEnabled ? pl.data.passwordEnabled : state.user.passwordEnabled,
        planFeatures:pl.data.monetizationPlan ? pl.data.monetizationPlan.featureSetting : null
      }
    } :
    action.type === 'UPDATE_CARD_FULFILLED' ? {
      user: Object.keys(pl).length > 0 ? {...state.user, billingInfo:{stripeToken: { card: pl }}} : {...state.user}
    }:
    action.type === 'REFRESH_FULFILLED' && pl ? {
      user: {...state.user,
        email: pl.data.email,
        name: pl.data.name,
        country: pl.data.country,
        phoneNumber: pl.data.phoneNumber ? pl.data.phoneNumber : null,
        registeredOn: pl.data.registeredOn,
        registerdVia: pl.data.registerdVia,
        emailConsent: (pl.data.emailConsent === true || pl.data.emailConsent === false )? pl.data.emailConsent : state.user.emailConsent,
        isSubscribed: pl.data.isSubscribed,
        subscriptionStatus : pl.data.subscription && pl.data.subscription.subscriptionStatus,
        // subscription : pl.data.subscription,
        parentalControl: {'parentalControl': pl.data.parentalControl, 'parentalPin': pl.data.parentalPin, 'parentalRating': pl.data.parentalRating},
        planFeatures:pl.data.monetizationPlan ? pl.data.monetizationPlan.featureSetting : null,
        passwordEnabled: pl.data.passwordEnabled,
        idpLogo: pl.data.idpLogo ? pl.data.idpLogo : null,
        idpName: pl.data.idpName ? pl.data.idpName : null,
        mvpdProvider: pl.data.mvpdProvider ? pl.data.mvpdProvider : null,
        // purchasedItems: pl.data.purchasedItems ? pl.data.purchasedItems : null,
        whatsappConsent: pl.data.whatsappConsent ? pl.data.whatsappConsent : null,
        userState: pl.data.userState ? pl.data.userState : null,
        redirectUrl: pl.data.monetizationPlan && pl.data.monetizationPlan.planDetails && pl.data.monetizationPlan.planDetails[0]  && pl.data.monetizationPlan.planDetails[0].redirectUrl,
        vppaCompliance: pl.data.vppaCompliance ? pl.data.vppaCompliance : null,
        hbaMetadata: pl.data.hbaMetadata ? pl.data.hbaMetadata : null,
        liveLikeNickName: pl.data.liveLikeNickName ? pl.data.liveLikeNickName : null,
      },
      subscription:{
        subscription : pl.data.subscription
      },
      purchasedItems : {
        purchasedItems : pl.data.purchasedItems ? pl.data.purchasedItems : null,
      }
    } :
    action.type === 'GET_SUBSCRIPTION_FULFILLED' ? {
      user: {...state.user,
        // subscription: action.payload,
        isSubscribed: !(
          action.payload.subscriptionInfo.subscriptionStatus === 'CANCELLED' || 
          action.payload.subscriptionInfo.subscriptionStatus === 'SUSPENDED' || 
          action.payload.subscriptionInfo.subscriptionStatus === 'HOLD' || 
          action.payload.subscriptionInfo.subscriptionStatus === 'PAUSED'
          
        ),
        subscriptionStatus: action.payload.subscriptionInfo.subscriptionStatus
      },
      subscription: {...state.subscription,
        subscription: action.payload
      }
    } :
    action.type === 'CREATE_SUBSCRIPTION_FULFILLED' ?  {
      user: {...state.user,
        isSubscribed: true
      }
    } :
    action.type === 'CANCEL_SUBSCRIPTION' ? {
      user: {...state.user,
        isSubscribed: false
      }
    } :
    action.type === 'CREATE_TRANSACTION' ? {
      user: {
        ...state.user,
        purchasedVideoIds: (state.user.purchasedVideoIds || []).push(pl.contentRequest.contentId)
      }
    }:action.type === 'PARENTAL_CONTROL' ? {
      user: {
        ...state.user,
        parentalControl : {...state.user.parentalControl, parentalControl: pl}
      }
    }:action.type === 'ADD_PIN' ? {
      user: {
        ...state.user,
        parentalControl : {...state.user.parentalControl, parentalPin: pl}
      }
    }:
    action.type === 'ADD_RATING' ? {
      user: {
        ...state.user,
        parentalControl : {...state.user.parentalControl, parentalRating: pl}
      }
    }:
    action.type === 'UPDATE_LATEST_TRANSACTION' ? {
      user: {
        ...state.user,
        transactionDetails: {...state.user && state.user.transactionDetails, ...action.payload}
      }
    }:
    action.type === 'ADD_PASSWORD' ? {
      user: {...state.user,
        passwordEnabled: pl
      }
    }:action.type === 'ADD_EMAIL_CONSENT' ? {
      user: {...state.user,
        emailConsent: pl
      }
    } : 
    action.type === 'ADD_CLEVERTAP_WHATSAPP_CONSENT' ? {
      user: {...state.user,
        whatsappConsent: pl && pl.whatsappConsent,
        phoneNumber: pl && pl.phoneNumber
      }
    } :
    action.type === 'TVE_LOGIN_FULFILLED' ? {
      user: {...state.user, ...pl}
    } :
    action.type === 'ADD_LOGIN_METHOD' ? {
      user: {...state.user,  login: pl}
    }:
    action.type === 'REFRESH_TVE' ? {
      user: {...state.user,  istveLoggedIn: pl}
    }:action.type === 'TVE_LOGIN_REFRESH' ? {
      user: {...state.user,...pl}
    }:
    null
  if (extention)
    state = extend({}, state, extention)
  
  Cookies.set('vl-user', state.user && state.user.userId)
  Cookies.set('vl-redirect-user', state.user && state.user.userId, { domain: `.${extract(window.location.hostname)}` }) 
  Cookies.set('user', JSON.stringify(state.user),{ expires: 365 })
  localStorage.setItem('subscription',JSON.stringify(state.subscription),{ expires: 365 })
  localStorage.setItem('purchasedItems',state.purchasedItems && state.purchasedItems.purchasedItems.length>0 ? JSON.stringify(state.purchasedItems) : null,{ expires: 365 })
  return state
}
