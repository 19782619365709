import Cookies from 'js-cookie'

export default (s = window.settings, langCode) => {
  const userLanguageSelection = 'userLanguageSelection'
  const lc = Cookies.get(userLanguageSelection)
  let output = 'default'
  let langFound = lc && s.languages && s.languages.languages.find(o => o.code === (lc).toLowerCase());
  let langCodeFound = langCode && s.languages && s.languages.languages.find(o => o.code === (langCode).toLowerCase());
    if (langCodeFound && !lc) {
      output = langCode;
      Cookies.set(userLanguageSelection, langCode)
    } else if (langFound) {
      output = lc
    } else {
      let defaultCode = (s.languages && s.languages.default.code) || ""
      Cookies.set(userLanguageSelection, defaultCode)
    }
    return output
}
