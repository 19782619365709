import React, { Fragment } from 'react'
import get from 'lodash/get'

import connect from '~/tools/connect'
import {hide, persolizationSetUpProfile } from './popup'
import store from './redux'
import userStore from '~/redux/user/store';
import './style.css'
import Cookies from 'js-cookie'
import { enableNonModalTabbing, disableNonModalTabbing } from '~/tools/dom/accessibility'
import { popupConstants as componentPathMap } from "./popupConstants";
import { removeVideoPurchase } from '~/redux/purchaseInProcess/helper'
import {getSvodRedirect} from '~/tools/getRedirectUrlFromPlan'
import { BackwardSvg2 } from "../../components/VideoPlayer/assets/icons"
class View extends React.Component {
  constructor() {
    super()
    this.state = { refresh: null, customStyle: {}, removeArgumentsFromURL: true }
  }

  componentDidMount() {
    const hideCloseButtonOnAddPhoneNumber = get(window.settings, 'features.otp_value.hideCloseButtonOnAddPhoneNumber');
    if(componentPathMap[this.state.show]){
      this.getPopup()

      if((hideCloseButtonOnAddPhoneNumber && this.state.show==="updateNumber") || this.state.show === "notice02"){
        // user will not be able to use esc button to close updateNumber popUp
      }
      else{
       window.addEventListener('keydown', e => (e.keyCode === 27) && hide())
      }
    }
  }

  updateCustomStyle(newCustomStyle){
    this.setState({customStyle: newCustomStyle})
  }
  
  shouldComponentUpdate(nextProps, nextState){
    const onViewPlansPage = window.location.href.includes("plans")
    if (onViewPlansPage && get(this.state, 'user.isSubscribed') && nextState.show === "hide"){
      const redirectUrl = getSvodRedirect()
      window.location.href = redirectUrl ?  redirectUrl : '/';
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.show === 'purchase' && this.state.show === 'EpisodeOverlay') {
      this.watchNow = true
    }

    // clear previous custom style for new popup
    if (Object.keys(prevState.customStyle).length !== 0 && prevState.show !== this.state.show){
      this.setState({customStyle:{}});
    }

    const refreshScreen = () => {
      if (this.state.refresh) {
        this.state.refresh()
      }
    }

    // refresh screen after 1. purchase has been completed and user closes overlay 
    // or 2. user goes to watch now, and then closes the overlay
    if (prevState.show === 'purchase' && this.state.show === 'hide') {
      refreshScreen()
    } else if (this.watchNow) {
      if (prevState.show === 'EpisodeOverlay' && this.state.show === 'hide') {
        refreshScreen()
        this.watchNow = false
      }
    }
    if(prevState.show !== this.state.show && componentPathMap[this.state.show]){
      this.getPopup()
    }
    if(this.state.show === "hide" && this.state.MyComponent !== undefined) {
      this.setState({MyComponent: undefined})
    }
  }
  getPopup = () => {
    if (componentPathMap[this.state.show]) {
      const path = componentPathMap[this.state.show];
      const PopupComponent = import(`${path}`);
      PopupComponent.then((popup) => {
        this.setState({ MyComponent: popup.default })
      })
    }
  }

  checkForCloseIcon = () => {
    if(document.getElementsByClassName("otpScreen")[0]){
      return true
    }
    return false ; 
  }
 
  render() {
    var setAppState = this.setState.bind(this)
    const { args, user, refresh } = this.state
    let MyComponent = this.state.MyComponent


    this.bodyHack(this.state.show)
    
    if (!MyComponent) {
      enableNonModalTabbing()
      return null
    }
    
    disableNonModalTabbing()
    const renderCloseButton = (args.showCloseButton === undefined) ? true : args.showCloseButton
    const hideCloseButton = (Cookies.get('isiDealPayment') ? true : false)|| (args.hideCloseButton);
    const ref = this;
    let userAgent = window.navigator.userAgent.toLowerCase()
    let isIPhone = (userAgent && userAgent.includes("iphone")) || null
    let currentComponent = this.state.show === "EpisodeOverlay"
    let enableOldPlayerUI = !window.settings.features.enableOldPlayerUI ? false : window.settings.features.enableOldPlayerUI  
    if(isIPhone && currentComponent) {
      window.sessionStorage.setItem("video-overlay",true)
    } else if(this.state.show !== "hide" && this.state.show !== "EpisodeOverlay") {
      window.sessionStorage.removeItem("video-overlay")
    }
    if(!isIPhone) {
      window.sessionStorage.removeItem("video-overlay")
    }

    const disablePesonalizationSkipFlag = ( window.settings.recommendation && window.settings.recommendation.disablePesonalizationSkip) || null;

    return (
      componentPathMap[this.state.show] && this.state.show !== "hide" ? (<div className={ `popup-view type-${this.state.show}` } style={this.state.customStyle}>
        <MyComponent 
          args={args} 
          user={user} 
          refresh={refresh} 
          setAppState={setAppState}
          onClose={ hide }
          removeArguments = {()=> this.setState({removeArgumentsFromURL: true})}
          updateCustomStyle = {this.updateCustomStyle.bind(this)}
        />
        
        {renderCloseButton && !hideCloseButton &&
          <div className={`close02 ${this.state.show}`} onClick={(evts)=>{
            if(this.state.removeArgumentsFromURL && window.location.href.includes("?")){
              window.history.pushState(null, '', window.location.href.split("?")[0]);
            }
            if(disablePesonalizationSkipFlag && this.state.show === 'forgotPassword') {
              window.location.reload()
            } else {
              args.redirectUrl? (window.location = args.redirectUrl) : (window.sessionStorage.getItem("video-overlay") ? window.location.reload() : hide())
            }
            if(Cookies.get('isThankYouPage') && (this.state.show === 'createSubscription02' || this.state.show === 'purchase02')){
              args.fn && args.fn() && Cookies.set('isThankYouPage', false);
            }
            switch (ref.state.show){
              case 'purchase':
              case 'episodeOverlay':
              case 'createSubscription':
                removeVideoPurchase();
                break;
              default:
                break;
            }
            
            if(!Cookies.get('isThankYouPage') || (Cookies.get('isThankYouPage')  &&  !Cookies.get('isThankYouPage').match('true'))){
              if(user && Cookies.get('isUserSignUp') && Cookies.get('isUserSignUp').match('true') && window.settings.recommendation && window.settings.recommendation.isPersonalization && window.settings.recommendation.recommendCategories && window.settings.recommendation.recommendCategories.length>0){
                if(!window.settings.recommendation.isSubscribed && !(sessionStorage.getItem('personalizationShown'))){
                  persolizationSetUpProfile() && Cookies.set('isUserSignUp', false) &&  Cookies.set('isThankYouPage', false);
                }
              }
            }

          }}>  
           { !enableOldPlayerUI ? <div className={this.state.show ==="EpisodeOverlay"? "back-close fixed-width" : window.innerWidth < 700 ? "" : "back-close m-imp"}>
           <i role="presentation" className={`ion-ios-close-empty ${window.innerWidth <700 ? "" : "right02"}`} onClick={()=> {
               var castSession = window.cast && window.cast.framework && window.cast.framework.CastContext.getInstance().getCurrentSession();
               // End the session and pass 'true' to indicate
               // that Web Receiver app should be stopped.
               if(castSession) {
                 castSession.endSession(true);
               }
           }}/>
            <div className="back02-title">
            {this.state.show ==="EpisodeOverlay"? <>
            <p className="popup-title"></p>
            <BackwardSvg2 className="back02" />
            </>
            : <></>}
            </div>
            </div>
            : <i role="presentation" className="ion-ios-close-empty right02" onClick={()=> {
              var castSession = window.cast && window.cast.framework && window.cast.framework.CastContext.getInstance().getCurrentSession();
              // End the session and pass 'true' to indicate
              // that Web Receiver app should be stopped.
              if(this.state.show ==="EpisodeOverlay" && castSession) {
                castSession.endSession(true);
              }
          }} />}
          </div>
        }
      </div>) : (<Fragment/>)
    )
  }

  bodyHack(shown) {
    const bcl = window.document.body.classList
    if (!bcl) 
      return
    const display = shown === "@@redux/INIT" || shown === "hide" ? false : true;
    if (display) {
      bcl.add('overflow-hide')
    } else if (bcl.contains('overflow-hide')) {
      bcl.remove('overflow-hide')
    }
  }
}


connect(View, (state) => ({ user: state.user }), userStore)
connect(View, (state) => ({ show: state.show, args: state.args || {} }), store)
export default View