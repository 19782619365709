export const disableNonModalTabbing = () => {
  if (window.SERVER_SIDE_RENDERING || typeof document === 'undefined') return
  
  // let nonModalArea = document.querySelector('.modules')
  // let nodesToRestrict = nonModalArea.querySelectorAll('a, button, iframe, [tabindex="0"]')
  // _.each(nodesToRestrict, node => node.tabIndex = -1)
}

export const enableNonModalTabbing = () => {
  if (window.SERVER_SIDE_RENDERING || typeof document === 'undefined') return
  
  // let nonModalArea = document.querySelector('.modules')
  // let nodesToRestrict = nonModalArea.querySelectorAll('a, button, iframe, [tabindex="-1"]')
  // _.each(nodesToRestrict, node => {
  //   if (!node.dataset.noFocus)
  //     node.tabIndex = 0 
  // })
}

export const getDomProperty = (classname, property, offset = 0) => {
  const elm = document.getElementsByClassName(classname);
  let value = null;
  if(elm.length > 0) {
    value =  elm[offset][property];
  }
  return value;
}