import axios from 'axios'
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'

var vl_user_data = Cookies.get('vl-user-data') && JSON.parse(Cookies.get('vl-user-data'));
var vl_user = vl_user_data && vl_user_data.user

let savedToken = Cookies.get('token')
let tokenData = vl_user ? setToken(vl_user) : (savedToken ? JSON.parse(savedToken) : {})
if (tokenData && tokenData.expiration) {
  tokenData.expiration = new Date(tokenData.expiration)
}

function decodeToken(token) {
  var authToken = jwtDecode(token);
  var tokenExp = authToken.exp
  var date = new Date(tokenExp * 1000);
  return date
}

export function setToken(t) {
  if (!t || !t.authorizationToken){
    throw(new Error('Invalid request to update token'))
  }
  var tokenExpiryTime = decodeToken(t.authorizationToken)
  tokenData = {
    expiration: tokenExpiryTime.getTime(),
    authorizationToken: t.authorizationToken,
    refreshToken: t.refreshToken,
    duration: tokenExpiryTime.getTime() - new Date().getTime(),
    profileId: t.profileId
  }
  Cookies.set('token', JSON.stringify(tokenData),{ expires:  365})
 return tokenData
}

export function clearToken() {
  console.log('Clearing token.')

  if (localStorage.getItem('user')) {
    localStorage.removeItem('user')
  }
  if (localStorage.getItem('token')) {
    localStorage.removeItem('token')
  }
  if (localStorage.getItem('emailProcessed')) {
    localStorage.removeItem('emailProcessed')
  }
  if (sessionStorage.getItem('profileId')) {
    sessionStorage.removeItem('profileId')
  }
  if (sessionStorage.getItem('loginFlag')) {
    sessionStorage.removeItem('loginFlag')
  }
  if (sessionStorage.getItem('userStateForExistingUser')) {
    sessionStorage.removeItem('userStateForExistingUser')
  }
  if (sessionStorage.getItem('personalizationShown')) {
    sessionStorage.removeItem('personalizationShown')
  }
  if (sessionStorage.getItem('skipPlanPage')) {
    sessionStorage.removeItem('skipPlanPage')
  }
  if (sessionStorage.getItem('noPlanAvailable')) {
    sessionStorage.removeItem('noPlanAvailable')
  }
  if (sessionStorage.getItem('clickOnVppaContinueBtn')) {
    sessionStorage.removeItem('clickOnVppaContinueBtn')
  }
  if (sessionStorage.getItem('clickedOnShareLocationBtn')) {
    sessionStorage.removeItem('clickedOnShareLocationBtn')
  }
  if (Cookies.get('isFilterSelectedRecently')) {
    Cookies.remove('isFilterSelectedRecently')
  }
  if (Cookies.get('isDeviceActivateMessage')) {
    Cookies.remove('isDeviceActivateMessage')
  }
  if(sessionStorage.getItem('deviceCode')){ 
    sessionStorage.removeItem("deviceCode")
  }
  if(sessionStorage.getItem('isDisplayPersonalizationScreen')){ 
    sessionStorage.removeItem("isDisplayPersonalizationScreen")
  }
  if (Cookies.get('hide_personalization')) {
    Cookies.remove('hide_personalization')
  }
  if (Cookies.get('isUserSignUp')) {
    Cookies.remove('isUserSignUp')
  }
  
  Cookies.remove('token')
  Cookies.remove('vl-user');
  Cookies.remove('personalizationShown');
  Cookies.remove('vl-redirect-user');
  Cookies.remove('user')
  Cookies.remove('subscription')
  Cookies.remove('MAX_DEVICE_ERROR');
  Cookies.remove('remove_error');
  Cookies.remove("user-profile");
  Cookies.remove("hide_personalization");
  window.localStorage.removeItem('deviceId')
  localStorage.removeItem('purchasedItems')
  localStorage.removeItem('userSelectedPlan')
  localStorage.removeItem('genericMessages')
  localStorage.removeItem('pages')
  tokenData = {}
  window.location.href = '/';
}

export function getToken(cb) {
  let user = Cookies.get('user')
  let userData = user ? JSON.parse(user) : {}

  return new Promise((resolve, reject) => {
    if (tokenData.authorizationToken) {
      var now = new Date().getTime();
      if (now < tokenData.expiration) {
        if (cb) cb(tokenData.authorizationToken, tokenData.refreshToken)
        resolve(tokenData.authorizationToken, tokenData.refreshToken)
        return
      }
    }

    if (tokenData.refreshToken || (userData && Object.keys(userData).length > 0)) {
      // console.log('refreshing token')
      axios({
        method: 'GET',
        url: `${ window.settings.apiBaseUrl }/identity/refresh/${ tokenData.refreshToken || userData.refreshToken }`,
      }).then( res => {
        var d = res.data && {
          authorizationToken: res.data.authorization_token,
          refreshToken: res.data.refresh_token
        }
        setToken(d)
        if (cb) cb(d.authorizationToken, d.refreshToken)
        resolve(d.authorizationToken, tokenData.refreshToken)
      }).catch(err => {
        console.error('Error refreshing token', err)
        clearToken()
        reject(err)
      })
      return
    }

    // If user, remove user
    var url = 
      window.SERVER_SIDE_RENDERING ? 
        `${ window.settings.apiBaseUrl }/identity/server-token` :
        `${ window.settings.apiBaseUrl }/identity/anonymous-token`

    // Fetch token
    axios({
      method: "GET",
      url: url,
      params: { site: window.settings.internalName },
      headers: {
        "x-api-key": window.settings.baseAPIKey
      }
    })
    .then(res => {
      var d = res.data && { authorizationToken: res.data.authorizationToken };
      setToken(d);
      if (cb) cb(d.authorizationToken, d.refreshToken);
      resolve(d.authorizationToken, d.refreshToken);
    })
    .catch(err => {
      console.error("Error fetching anonymous token", err);
      reject(err);
    });
  })
}
export const decodeAuthToken = () => {    
  if (tokenData) {
    const authorizationToken = tokenData && tokenData.authorizationToken;
    const decodeAuthToken = authorizationToken && jwtDecode(authorizationToken)
    return decodeAuthToken
  } else {
    return null
  }
}