import get from 'lodash/get';
import axios from 'axios';
import { getToken } from '../../redux/user/tokens';

export const FB_EVENTS = {
  PAGE_VIEW: 'PageView',
  PURCHASE: 'Purchase',
  ADD_PAYMENT_INFO: 'AddPaymentInfo',
  INITIATE_CHECKOUT: 'InitiateCheckout',
  COMPLETE_REGISTRATION: 'CompleteRegistration',
  ADD_TO_CART: 'AddToCart',
  START_TRIAL: 'StartTrial',
  SUBSCRIBE: 'Subscribe',
  VIEW_CONTENT: 'ViewContent',
  ADD_TO_WISHLIST: 'AddToWishlist',
  REMOVED_FROM_WATCHLIST: 'RemovedFromWatchlist',
  SUBSCRIPTION_INITIATED: 'SubscriptionInitiated',
  SUBSCRIPTION_COMPLETED: 'SubscriptionCompleted',
  SUBSCRIPTION_FAILED: 'SubscriptionFailed',
  TVOD_PURCHASE: 'tvod_purchase',
  TVOD_PURCHASE_COMPLETION: 'tvod_purchase_completion',
  SEARCH: 'Search',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  PLAY_STARTED: 'Play Started',
  WATCHED: 'Watched',
};

export const pixelEvents = {
  ADD_TO_CART: 'AddToCart',
  PAGE_VIEW: 'PageView',
  PURCHASE: 'Purchase',
  ADD_PAYMENT_INFO: 'Add Payment Info',
  INITIATE_CHECKOUT: 'Initiate Checkout',
  LOGIN: 'Login',
  VIEW_CONTENT: 'ViewContent',
  COMPLETE_REGISTRATION: 'Complete Registration',
  SUBSCRIPTION_INITIATED: "SubscriptionInitiated",
  SUBSCRIPTION_COMPLETED: 'SubscriptionCompleted'
}

export const trackFbPixel= ( eventName,payload = {}, eventID ) => {
  const socialMedia = window.settings.socialMedia
  const facebook = socialMedia && socialMedia.facebook 
  const pixelId = facebook && facebook.pixelId
  const facebookAdditionalPixelIdsInArray = (facebook && facebook.facebookAdditionalPixelIds && facebook.facebookAdditionalPixelIds.split(',')) || []
  if(pixelId || (facebookAdditionalPixelIdsInArray && facebookAdditionalPixelIdsInArray.length > 0)) {
    window.fbq && window.fbq('track', eventName, payload,{eventID : eventID})
  }
}


export const trackFbEvent = async (
  eventName,
  payload = {},
  eventID
) => {
  const site = get(window, 'settings.internalName');
  const { conversionApi: conversionApiEnabled } = get(
    window,
    'settings.analytics.conversionApi'
  ) || {};

  if (!site) return;

  // Will be replaced with actual url
  if (conversionApiEnabled) {
    const url = `${window.settings.trackingBaseUrl}/conversion-api`;
    const token = await getToken();
    const { data } = axios({
      url,
      method: 'POST',
      params: { site, platform: 'web' },
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      data: {
        eventName,
        payload,
        eventID
      },
    })
    .then((response) => {
      return data;
    }).catch(error =>{
      return null
    })
  }
};