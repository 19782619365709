import React, { Component } from 'react'
import { getLocalizedVal } from '~/tools/localization/helpers'
import isUndefined from 'lodash/isUndefined'
import './style.scss'

// This component will catch any errors in any of its children
class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    //console.error(error)
    console.log(info)    
  }

  // TODO: maybe pass a prop for each component, either `important` or not, and if the module is like, important for the user, we should render some error UI, if its not important, (like some extra tray) we can just render nothing?
 render() {
   const moduleErrorMessage = isUndefined(getLocalizedVal(window.settings,'genericMessages','moduleErrorMessage')) ? 'Something went wrong.': getLocalizedVal(window.settings,'genericMessages','moduleErrorMessage');
    if (this.state.hasError) {
      // You can render any custom fallback UI
    if(moduleErrorMessage) return <div className="module-error-msg">{moduleErrorMessage}</div>
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
