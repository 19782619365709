import Cookies from 'js-cookie'
import allLanguageCodes from '~/tools/localization/languageCodes'
import { getToken } from "~/redux/user/tokens";
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { userLanguageSelector } from '~/tools/localization'
//This function is used to scan the entire modules array and locate all video Ids currently on the page
export function getVideoIdsFromPayload(modules) {
  const ids = [];

  function getIdFromObjectIfVideo(obj) {
    if (Array.isArray(obj)) {
      obj.forEach(getIdFromObjectIfVideo);
    }
    else if (typeof obj === 'object' && obj != null) {
      Object.keys(obj).forEach(key => {
        if (key === 'contentType' && obj[key] === "VIDEO") {
          ids.push(obj['id']);
        }
        getIdFromObjectIfVideo(obj[key]);
      })
    }
  }

  getIdFromObjectIfVideo(modules);

  return ids;
}

//This function is used to scan the modules array and locate the series ID if it is a show page.
export function getSeriesIdFromPayload(content) {

  let seriesId;

  if (content.title === 'Show Page') {
    content.modules.forEach(module => {
      if (module.moduleType === "ShowDetailModule" && module.contentData) {
        seriesId = module.contentData[0].id;
      }
    })
  }

  return seriesId;
}


//convert history array to object
export function formatHistory(history) {
  const fh = {};
  history.forEach((datum) => {
    fh[datum.videoId] = {
      watchedPercentage: datum.watchedPercentage,
      watchedTime: datum.watchedTime,
      latestEpisode: datum.latestEpisode
    };
  });
  return fh;
}

//This function takes in an object pairing videos Ids to watchTime and adds this field to relevant fields in the payload.
//Very general and not optimized
export function addWatchHistoryToPayload(historyPairs, obj) {

  if (Array.isArray(obj)) {
    obj.forEach((o) => addWatchHistoryToPayload(historyPairs, o));
  }
  else if (typeof obj === 'object' && obj != null) {
    Object.keys(obj).forEach(key => {
      const contentId = obj.originalObjectId || obj.id
      if (key === 'contentType' && obj[key] === "VIDEO" && historyPairs[contentId] !== undefined) {
        obj.watchHistory = historyPairs[contentId];
      }
      addWatchHistoryToPayload(historyPairs, obj[key]);
    })
  }

}

//Add episode numbers and season title to payload if on a show page. Also a hack, want this info from pages API.
export function addEpisodeNumbersToPayload(content) {

  if (content.title === 'Show Page') {
    content.modules.forEach(module => {
      if (module.moduleType === "ShowDetailModule" && module.contentData) {
        const seasons = module.contentData[0].seasons;
        seasons.forEach((season, n) => {
          season.episodes.forEach((episode, i) => {
            episode.gist = {
              ...episode.gist,
              seasonTitle: season.title,
              seasonNumber: n,
              episodeNumber: i + 1
            };
          });
        });
      }
    })
  }

}

//Fetch user's current country code and corresponding language code 
export const fetchCurrentLanguage = async (languageCode = null, country) => {
  let overriddenCode = ''
  let selectedCode = ''
  if(!country) {
    const token = await getToken()
    var decodedToken = jwtDecode(token);
    country = decodedToken.countryCode
  }
  
  if (Cookies.get('userLanguageTrigger') !== "true" && !languageCode) {
    let countryCode = country;
    countryCode = countryCode && countryCode.toLowerCase()
    selectedCode = allLanguageCodes[countryCode]
    const languages = window.settings.languages && window.settings.languages.languages;
    if (languages) {
      const matchedCode = languages.find((langCode) => langCode.code === selectedCode);
      if (matchedCode) {
        overriddenCode = matchedCode.code
        Cookies.set('userLanguageSelection', overriddenCode)
        Cookies.set('userLanguageTrigger', false)
        Cookies.set('userCountrySelection', countryCode)
      }
    }

    return overriddenCode;
  }
  return languageCode
}

export const personalizationPageCheck = (getUserId) => {
  const recommendationArr = window.settings.recommendation || null;
  const isPersonalizationFlag = (recommendationArr && recommendationArr.isPersonalization) || null;
  const personalizationForLoggedIn = (recommendationArr && recommendationArr.showPersonalizationForLoggedIn) || null;
  // const disablePesonalizationSkipFlag = (recommendationArr && recommendationArr.disablePesonalizationSkip) || null;
  const recommendCategoriesArr = recommendationArr && recommendationArr.recommendCategories;  
  if(!(sessionStorage.getItem('personalizationShown')) && getUserId && 
  isPersonalizationFlag && personalizationForLoggedIn && recommendCategoriesArr && recommendCategoriesArr.length >0) {
    return true
  } else {
    return false
  }
}

export const getUserPersonalizationCheck=async() => {
  const token = await getToken()
  const url = `${window.settings.apiBaseUrl}/identity/user?${window.settings.internalName}`
  let teams=[]
  const players=[]
  try {
    const { data } = await axios.get(url, {
      headers: {
        'x-api-key': window.settings.baseAPIKey,
        Authorization: token,
      },
    })
    const getTemData = data && data.profiles && data.profiles[0] && data.profiles[0].metadata && data.profiles[0].metadata.teams;
    const getPlayerData = data && data.profiles && data.profiles[0] && data.profiles[0].metadata && data.profiles[0].metadata.players;

    if(getTemData){
      teams.push(getTemData)
    }
    if(getPlayerData){
      players.push(getPlayerData)
    }
    if((teams && teams.length > 0 || players && players.length > 0) ) {
      return true
    } else {
      return false
    }
  } catch (error) {
    console.log(error)
    return null
  }
}

export const userSelectedGenres = (getUserId, persolizationSetUpProfile, blockName) => {
  return new Promise((resolve, reject) => {
    getToken().then(token => {
      axios({
        method: "GET",
        url:`${window.settings.apiBaseUrl}/user/recommendations/getUserGenres`,
        params: {
          site: window.settings.internalName, userId: getUserId
        },
        headers: {
          Authorization: token,
          "x-api-key": window.settings.baseAPIKey
        }
      })
      .then(result => {
        if(result && result.data){
          var selectedGenreArray = (result.data.genreValue && result.data.genreValue.split("|")) || [];
          if(selectedGenreArray.length < 1 && persolizationSetUpProfile && !Cookies.get('personalizationShown')) {
            persolizationSetUpProfile(true,null, blockName);
            resolve(true)
          } else {
            resolve(false)
            return selectedGenreArray
          }
        }
      }).catch((error)=>{
        console.log(error);
        reject()
      });
    })
  });
}
export const fetchPersonalizationPageData = () =>{
  const { internalName } = window.settings;
  return new Promise((resolve, reject) => {
    getToken(token => {
      let baseUrl = ""
      var decodedToken = jwtDecode(token);
      var tokenCountryCode = decodedToken.countryCode
      if(window.settings.apiBaseUrl === "https://prod-api.viewlift.com") {
        baseUrl = "https://prod-api-cached-2.viewlift.com"
      } else {
        baseUrl = window.settings.apiBaseUrlCached || window.settings.apiBaseUrl
      }
      axios({
        method: "GET",
        url: `${baseUrl}/content/pages?site=${internalName}&path=/personalization&languageCode=${userLanguageSelector()}&includeContent=true&countryCode=${tokenCountryCode}`,
        headers: { Authorization: token }
      })
      .then(res => {
      axios({
          method: "GET",
          url: `${window.settings.layoutUrlBase}${window.settings.siteId}/web/${res.data.id}.json`
        })
        .then(moduleListRes => {
          const moduleIndex = moduleListRes.data.moduleList.findIndex(x => x.view === 'AC Personalization 03')
          if(moduleIndex > -1) {
            resolve(moduleListRes.data.moduleList[moduleIndex])
          } else {
            resolve(false)
          }
        })
      })
      .catch(err => console.error(err));
    });
  });
}

export const getCategories = () => {
  return new Promise((resolve, reject) => {
    return getToken().then(token => {
      return axios({
        method: 'GET',
        url:`${window.settings.apiBaseUrl}/content/categories/personalize?site=${window.settings.internalName}`,
        headers: {
          Authorization: token,
          "x-api-key": window.settings.baseAPIKey
        }
      })
      .then(response => {
        const getRecords = response.data.records
        let teamsData = []
        if(getRecords && getRecords.length > 0 ){
          getRecords.map((record) => {
            let teamName = (record && record.gist && record.gist.title) || '';
            let teamImage = (record && record.gist && record.gist.images["_1x1"].url) || '';
            let teamData = {
              name: teamName,
              waysToWatch: ["nhllive", "some other broadcast"],
              imageUrl: teamImage,
            }
            teamsData.push(teamData);
          })
          Cookies.set('teamsData', teamsData);
          resolve(true)
        } else {
          Cookies.set('teamsData', 'no_team');
          resolve(false)
        }
      })
      .catch(err => {
        console.error(err)
      });
    })
  });
}

export const fetchPersonalizationPageDataSteps = () =>{
  const { internalName } = window.settings;
  return new Promise((resolve, reject) => {
    getToken(token => {
      let baseUrl = ""
      var decodedToken = jwtDecode(token);
      var tokenCountryCode = decodedToken.countryCode
      if(window.settings.apiBaseUrl === "https://prod-api.viewlift.com") {
        baseUrl = "https://prod-api-cached-2.viewlift.com"
      } else {
        baseUrl = window.settings.apiBaseUrlCached || window.settings.apiBaseUrl
      }
      axios({
        method: "GET",
        url: `${baseUrl}/content/pages?site=${internalName}&path=/personalisation&languageCode=${userLanguageSelector()}&includeContent=true&countryCode=${tokenCountryCode}&site=${internalName}`,
        headers: { Authorization: token ,  "x-api-key": window.settings.baseAPIKey }
      })
      .then(res => {
      axios({
          method: "GET",
          url: `${window.settings.layoutUrlBase}${window.settings.siteId}/web/${res.data.id}.json`
        })
        .then(moduleListRes => {
          const moduleIndex = moduleListRes.data.moduleList.findIndex(x => x.view === 'AC User Personalisation')
          if(moduleIndex > -1) {
            resolve(moduleListRes.data.moduleList[moduleIndex])
          } else {
            resolve(false)
          }
        })
      })
      .catch(err => console.error(err));
    });
  });
}

export const getUserData = async () => {
  const token = await getToken()
  const url = `${window.settings.apiBaseUrl}/identity/user?${window.settings.internalName}`
  try {
    const { data } = await axios.get(url, {
      headers: {
        'x-api-key': window.settings.baseAPIKey,
        Authorization: token,
      },
    })
    return data
  } catch (error) {
    console.log(error)
    return null
  }
}
export const autoSendRecommendationCategories = () => {
  const categories = (Cookies.get('teamsData') && Cookies.get('teamsData') !== 'no_team') ? JSON.parse(Cookies.get('teamsData')) : []
  
  let userGenres = categories.map((item) => {
    return item && item.name
  })
  return getToken().then(token => {
    return axios({
      method: 'POST',
      url:`${window.settings.apiBaseUrl}/user/recommendations/saveUserRecommendation?site=${window.settings.internalName}`,
      data: {
        "genreValue": userGenres.join("|"),
        "userIdValue":Cookies.get('vl-user')
      },
      headers: {
        Authorization: token,
        "x-api-key": window.settings.baseAPIKey
      }
    })
    .then(response => {
      sessionStorage.setItem('personalizationShown', true);
      return true
    })
    .catch(function (error) {
      console.error(error)
    });
  })
}