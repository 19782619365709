const lazyLoad = function () {
  let lazyImages = [].slice.call(document.querySelectorAll(".lazy"));
  setTimeout(function () {
    lazyImages.forEach(function (lazyImage) {
      if (lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0 &&  getComputedStyle(lazyImage).display !== "none" && lazyImage.classList.contains("lazy")) {
        lazyImage.src = lazyImage.dataset.src;
        lazyImage.classList.remove("lazy");
        lazyImages = lazyImages.filter(function(image) {
          return image !== lazyImage;
        });
      }
    });
  }, 200);
}

export default lazyLoad