import axios from 'axios'
import { getToken } from '../user/tokens'
import { addEpisodeNumbersToPayload, fetchCurrentLanguage } from './helpers';
import Cookies from 'js-cookie';
import userLanguageSelector from '~/tools/localization/userLanguageSelector'
import jwtDecode from 'jwt-decode'
import allLanguageCodes from '~/tools/localization/languageCodes'
import {getUserState} from '~/redux/user/helpers'

export const setLoadingPage = fetching => {
  return {
    type: 'FETCHING_PAGE',
    payload: fetching
  };
};

export const fetchPage = (route, langCode, userId) => async (dispatch) => {
  if (userId == null) {
    userId = Cookies.get('vl-user');
  }
  dispatch(setLoadingPage(true));
  var languageCode = ""
  const token = await getToken()
  var decodedToken = jwtDecode(token);
  var tokenCountryCode = decodedToken.countryCode
  if (langCode === "" && window.settings.languages) {
    languageCode = await fetchCurrentLanguage(null, tokenCountryCode);
    languageCode = window.settings.languages.default.code
    Cookies.set('userLanguageSelection', languageCode)
  } else if(!langCode && window.settings.languages) {
    let selectedCode;
    if(Cookies.get('userLanguageTrigger') !== "true"){
      let countryCode = tokenCountryCode;
      countryCode = countryCode && countryCode.toLowerCase()
      selectedCode = allLanguageCodes[countryCode]
    }
    languageCode = userLanguageSelector(window.settings, selectedCode)
  } else {
    languageCode = langCode
  }

  var
    s = window.settings,
    content,
    layout
    let apiUrl = ""
		if(s.apiBaseUrl === "https://prod-api.viewlift.com") {
			apiUrl = "https://prod-api-cached-2.viewlift.com"
		} else {
			apiUrl = s.apiBaseUrlCached || s.apiBaseUrl
    }
    var contentUrl = apiUrl + s.pageEndpoint
  var contentApiHeaders = {
    'x-api-key': s.baseAPIKey
  }
  var defaultModuleLimit = 4
  if(window && window.settings && window.settings.features && window.settings.features.defaultModuleLimit) {
    defaultModuleLimit = window.settings.features.defaultModuleLimit
  }

  return axios
    .get(contentUrl, {
      params: {
        path: route,
        site: s.internalName,
        includeContent: true,
        moduleOffset: 0,
        moduleLimit: defaultModuleLimit,
        languageCode : languageCode ? languageCode : "default",
        countryCode: tokenCountryCode,
        userState: getUserState()
      },
      headers: contentApiHeaders
    })
    .then(r => {
      content = r.data

      // const isVideoDetailPage = find(content.modules, function(o){ return o.moduleType === "VideoDetailModule"})

      // if(isVideoDetailPage && getFeature("showDetailToggle")){
      //   const contentData = isVideoDetailPage.contentData && isVideoDetailPage.contentData[0]
      //   const seriesDetails = contentData && contentData.series && contentData.series[0]
      //   if(contentData && seriesDetails){
      //     const showPermalink = get(seriesDetails, 'gist.permalink')
      //     const videoId = get(contentData, 'gist.id')
      //     const redirectLink = showPermalink+'?v='+videoId;
      //     window.location.href = redirectLink
      //   }
      // }

      addEpisodeNumbersToPayload(content);

      var layoutUrl = `${s.layoutUrlBase}${s.siteId}/web/${content.id}.json`
      return axios.get(layoutUrl).then(s => layout = s.data)
    })
    // Get watch history for videos if we are on a showpage. This is a hack as the information would ideally be already in the pages API
    // UPDATED: Do it on client side too now
    .then(_ => {
      const payload = {
        title: content.title,
        categoryMap: content.categoryMap,
        metadataMap: content.metadataMap,
        id: content.id,
        modulesCount: content.moduleCount,
        modules: content.modules,
        layouts: layout.moduleList,
        styles: layout.pageStyle,
        route: route,
        lang: languageCode
      }
      dispatch({ type: 'FETCH_PAGE', payload: payload })
      dispatch(setLoadingPage(false));
    })
    .catch(e => {
      dispatch({ type: 'FETCH_PAGE_REJECTED', payload: e.response })
      dispatch(setLoadingPage(false));
    })
}
