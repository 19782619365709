import {  getCardInfo, refresh, loginMethod, magicLink } from '~/redux/user/actions'
import userStore from '~/redux/user/store'
import { fetchUserSubscription } from '~/redux/user/helpers'
import { addUserProfile, trackEvent } from '~/tools/analytics/cleverTap'
import {trackFbEvent, FB_EVENTS } from '~/tools/analytics/fbPixel';
import { setRemovePushNotificationFlag } from '~/tools/webWorker'


export function generateUUID() {
    var d = new Date().getTime();
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;
        if(d > 0){
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export async function verifyMagicLink(token) {
    let action = await magicLink(token);
    console.log(action)
    userStore
    .dispatch(action)
    .then(async (_) => {
        var userInfo = userStore.getState().user || {}
        userInfo && addUserProfile("Site", {
          Name: userInfo.name,
          Email: userInfo.email,
          Identity: userInfo.userId,
          "User Status": userInfo.isSubscribed
            ? "Subscribed"
            : "Registered and Never Subscribed"
        });
        setRemovePushNotificationFlag();
        if (userInfo && userInfo.userId && userInfo.isSubscribed){
            fetchUserSubscription();
        }
        trackFbEvent(FB_EVENTS.LOGIN, {platform: 'Web', registrationType: 'NATIVE'});
        trackEvent('Login', {platform: 'Web', registrationType: 'NATIVE'})
        await userStore.dispatch(getCardInfo(userInfo))
        sessionStorage.removeItem("sessionFlag");
        userStore.dispatch(refresh())
        userStore.dispatch(loginMethod('email'))

        // setTimeout(function () {
        //     window.location.href = window.location.pathname
        // }, 500);
        // return false;
        
    })
}