export function getProp(object, keys, defaultVal) {
  keys = Array.isArray(keys) ? keys : keys.split(".");
  object = object[keys[0]];
  if (object && keys.length > 1) {
    return getProp(object, keys.slice(1), defaultVal);
  }
  return object === undefined ? defaultVal : object;
}

export function strtrunc(str, max, add){
  add = add || '...';
  return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
};