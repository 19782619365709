import React, { Component } from 'react'
import appStore from '~/assets/images/app-store.png'
import googlePlay from '~/assets/images/google-play.png'
import browser from '~/tools/userEnvironment/browser'
import Cookies from 'js-cookie'
import { CloseIcon } from '~/assets/svg'
import './style.scss'
import { getLocalizedVal } from '~/tools/localization/helpers';

class AppStoreSuggestion extends Component {
  state = {
    AppStoreSuggestion: 1,
    isAndroid: false,
    isIos: false,
  }

  componentDidMount() {
    this.setState({ 
      AppStoreSuggestion: parseInt(Cookies.get('AppStoreSuggestion'), 10), 
      isAndroid: browser.isAndroid(), 
      isIos: browser.isIOS(), 
    })
  }

  addCookie = () => {
    // store cookie for a day
    const expiration = new Date(Date.now() + 1000 * 60 * 60 * 24)
    Cookies.set('AppStoreSuggestion', 1, { expires: expiration })
    this.setState({ AppStoreSuggestion : 1 })
  }

    render() {    
    const { google_playstore_url, itunes_store_url } = window.settings.features
    const { isIos, isAndroid, AppStoreSuggestion } = this.state
    let url, icon

    if (isIos) {
      icon = appStore;
      url = itunes_store_url;
    } else if (isAndroid) {
      icon = googlePlay;
      url = google_playstore_url;
    }
    const openInApp=getLocalizedVal(window.settings,'genericMessages', 'viewInAppMessage');

    if (AppStoreSuggestion === 1 || !(isAndroid || isIos)) return <div/>

    return (
      <div 
        className="appstore-suggestion" 
        tabIndex="1" 
        role="dialog" 
        aria-describedby="appstore-suggestion-desc" 
        aria-label="appstoreSuggestion"
      >
        <div id="appstore-suggestion-desc" className="desc">
          <span>{openInApp ? openInApp : "Would you like to view this in the app"}</span>
          <span>
            <a target="_blank" tabIndex="1" href={url} rel="noopener noreferrer">
              <img src={icon} alt="store Icon"/>
            </a>
          </span>
        </div>
        <div 
          aria-label="" 
          tabIndex="1" 
          className="close-button" 
          onKeyPress={this.addCookie} 
          onClick={this.addCookie}
        > 
          <CloseIcon/> 
        </div>
      </div>
    )
  }
}

export default AppStoreSuggestion;
