export default {
  mmap: {
    "AC Masthead 01": "Masthead01",
    "AC Masthead 02": "Masthead02",
    "AC Masthead 03": "Masthead03",
    "AC Masthead 04": "Masthead04",
    "AC CustomNavigation 01": "Masthead05",
    "AC NewsMasthead 01" : "Masthead06",
    "AC BrandNavigation 01": "BrandNavigation",
    "AC Footer 01": "Footer/Footer01",
    "AC Footer 02": "Footer/Footer02",
    "AC Footer 03": "Footer/Footer03",
    "AC Footer 04": "Footer/Footer04",
    "AC Tray 01": "Tray/Video",
    "AC Tray 11": "Tray/Video/NewsVideoTray",
    "News Tray 02": "Tray/Video/CircularVideoTray",
    "AC Tray 06": "Tray/FitnessMoreInfo",
    "AC Tray 07": 'Tray/Concept',
    "AC NetflixTray 01": "Tray/Video/netflix-tray",
    "AC Tray 12": "Tray/Video/9x16-tray",
    "AC Tray 08": "Tray/Video/category-tray",
    "AC ScheduleCarousel 01": 'Carousel/scheduleCarousel',
    "AC EventCarousel 03": "LiveSchedule",
    "AC SearchFilter 02": "BrowseConcepts",
    "AC EventCarousel 04": "LiveSchedule02",
    "AC Carousel 02": 'Carousel/PersonCarousel',
    "AC Carousel 03": 'Carousel/conceptCarousel',
    "AC BrandTray 01": 'Tray/Brand',
    "AC Following 01": 'Following',
    // HACK TRAY ONLY FOR SHOWING SHOWS ON WEB ONLY AND NOT APPS
    // PLEASE DELETE THIS ONCE WE LAUNCH SHOWS FOR OTHER PLATFORMS
    'AC Tray XX': 'Tray/Video',
    'AC Tray 02': 'Tray/Video/sports-template-tray',
    'AC Page 01': 'Tray/PageTray2',
    'AC PreviewTray 01': 'Tray/Video/preview-tray',
    'AC Carousel 01': 'Carousel',
    'AC Carousel 07': 'Carousel/NewsCarousel',
    'AC FullWidthCarousel 01': 'FullWidthCarousel',
    'AC EventCarousel 01': 'Carousel/Event',
    'AC RichText 01': 'RichText',
    'AC RawHtml 01': 'RawHtml',
    'AC WebFrame 01': 'WebFrame01',
    'AC WebFrame 02': 'WebFrame02',
    'AC WebFrame 03': 'WebFrame02',
    'AC Grid 01': 'CategoryPage',
    'AC TagDetail 01': 'CategoryPage',
    'AC Watchlist 01': 'Watchlist',
    'AC Watchlist 03': 'NewsWatchlist',
    'AC History 01': 'History',
    'AC History 04': 'NewsHistory',
    'AC VideoPlayerWithInfo 01': 'VideoPlayerWithInfo01',
    'AC VideoPlayerWithInfo 02': 'VideoPlayerWithInfo02',
    'AC VideoPlayerWithInfo 03': 'VideoPlayerWithInfo03',
    'AC VideoPlayerWithInfo 04': 'VideoPlayerWithInfo04',
    'AC VideoPlayerWithInfo 05': 'VideoPlayerWithInfo05',
    'AC VideoPlayerWithInfo 06': 'VideoPlayerWithInfo06',
    'AC VideoPlayerWithInfo 07': 'VideoPlayerWithInfo07',
    'AC StandaloneVideoPlayer 01': 'StandaloneVideoPlayer/StandaloneVideoPlayer01',
    'AC StandaloneVideoPlayer 02': 'NewsStandaloneVideoPlayer',
    'AC StandaloneVideoPlayer 04': 'StandaloneVideoPlayer04',
    'AC UserManagement 01': 'User',
    'AC UserManagement 02': 'User',
    'AC UserManagement 03': 'NewsUser',
    'AC UserManagement 04': 'User04',
    'AC UserManagement 06': 'User06',
    'AC ResetPassword 01': 'ResetPassword',
    'AC CreatePassword 01': 'CreatePassword',
    'AC ContinueWatching 01': 'ContinueWatching',
    'AC ContinueWatching 02': 'WatchHistoryHorizontal',
    'AC ContentBlock 01': 'ContentBlock',
    'AC ContentBlock 03': 'ContentBlock03',
    'AC SelectPlan 01': 'SelectPlans01',
    'AC SelectPlan 02': 'SelectPlans02',
    'AC SelectPlan 05': 'CustomSelectPlan',
    'AC Referral Plans': 'ReferralSelectPlan',
    'AC RedeemOffer 01': 'RedeemOffer',
    'AC RedeemOffer 02': 'RedeemOffer02',
    'AC ActivateDevice 01': 'ActivateDevice',
    'AC ImageTextRow 01': 'ImageTextRow',
    'AC ShowDetail 01': 'ShowPage',
    'AC ShowDetail 06': 'NewsShowDetail',
    'AC ShowDetail 04': 'ConceptDetail',
    'AC ShowDetail 07': 'WorkoutShowPage',
    'AC ShowDetail 08': 'ShowPage08',
    'AC Banner 01': 'TeamMasthead',
    'AC Banner 02': 'ImageBanner',
    'AC BannerAd 01': 'BannerAd',
    'AC EmbeddedVideoPlayer 01': 'EmbedPlayer',
    'AC ArticleTray 01': 'Tray/Article',
    'AC ArticleDetail 01': 'Article',
    'AC PhotoGallery 01': 'PhotoGallery',
    'AC EventCalendar 01': 'Events',
    'AC ArticleFeed 01': 'StackedArticles',
    'AC CarouselWithList 01': 'ModulesContainer',
    'AC CarouselWithList 02': 'ModulesContainer02',
    'AC EventDetail 01': 'EventDetail',
    'AC SmallEventCalendar 01': 'SmallCalendar',
    'AC MediumRectangleAd 01': 'MediumRectangleAd',
    'AC GameDetail 01': 'SportEvent',
    'AC FightDetail 01': 'SportEvent',
    'AC TeamDetail 01': 'TeamDetail',
    'AC TeamStats 01': 'TeamStats',
    'AC TeamRoster 01': 'TeamRoster',
    'AC PersonDetail 01': 'PersonDetail01',
    'AC PersonDetail 02': 'PersonDetail02',
    'AC PersonDetail 03': 'PersonDetail03',
    'AC PersonDetail 04': 'PersonDetail04',
    'AC TeamSchedule 01': 'TeamSchedule',
    'AC LoginHardwall 01': 'LoginHardwall',
    'AC SplashPage 01': 'SplashPage',
    'AC ScheduleTray 01': 'ScheduleTray',
    'AC FighterRoster 01': 'FighterRoster',
    'AC Library 01': 'Library',
    'AC BundleDetail 01': 'Bundle',
    'AC BundleDetail 02': 'Bundle02',
    'AC Custom 01': 'Custom',
    'AC ContentHtml 01': 'ContentHtml',
    'AC SearchFilter 01': 'SearchResults',
    'AC VideoPlaylistDetail 01': 'VideoPlaylistDetail',
    "AC Achievements 01": "Achievements",
    'AC Profile 02': 'ViewNeoProfile',
    'AC SportsRadarTabs 01': 'TeamTabs',
    'AC SportsTicker 01': 'SportsTicker',
    'AC Library 02': 'LibraryByType',
    "AC Carousel 04": 'Carousel/carousel32',
    "AC Carousel 05": 'Carousel/carousel32',
    'AC LogoGrid 01': 'LogoGrid',
    'AC Tray 10': 'Tray/Video/PosterVideoTray/poster-video-tray',
    'AC Image Header': 'BigImageHeader',
    'AC Video Header': 'BigVideoHeader',
    'AC SMTStats 01': 'SMTStats',
    'AC PageLink 01': 'PageLink',
    'AC Payment 01': 'JusPay',
    'AC Recommendation 01': 'RecommendationTray',
    'AC RecommendPopular 01': 'RecommendPopularTray',
    'AC DeviceAuth 01': 'DeviceAuth',
    'AC DeviceAuth 02': 'DeviceAuthVerimatrix',
    'AC Search 01': "SearchBar",
    'AC User Personalization 01' : 'UserPersonalization',
    'AC ReferAndEarn 01' : 'ReferAndEarn',
    'News Recommendation 01': 'Tray/Video/NewsVideoTray/recommendationTray',
    'News ContinueWatching 01': 'Tray/Video/NewsVideoTray/continueWatchingTray',
    "AC EPGFeed 01": 'EPG',
    "AC Thankyou 01" : 'Thankyou01',
    "AC leaderboardStandalonePlayer":"LeaderboardStandalonePlayer",
    "AC LeaderboardModule 01": "Leaderboard",
    "AC User Personalisation":"UsersPersonalization",
    "AC LeaderboardStats 01":"LeaderboardStats",
    "AC SeasonStandings 01":"SeasonStanding",
    "AC TeamChampionshipStandalonePlayer": "TeamChampionshipStandalonePlayer",
    "AC TeamChampionshipLeaderboard": "TeamChampionshipStandalonePlayer",
    "AC promotionalStandalonePlayer": 'PromotionalLeaderboard',
    "AC promotionalLeaderboard": 'PromotionalLeaderboard',
    "AC Banner 03": 'Carousel/ImageCarousel',
  }
};
