import store from './redux'
import { getSubscriptionPlans, getInvisiblePlans } from '~/redux/user/subscription'

export default {
  createSubscription: (i, type) => store.dispatch({ type: 'createSubscription', args: { selected: i, type } }),
  login: priceType => store.dispatch({ 'type': 'login', 'args': priceType }),
  createSubscription02: (i, fn, allPlans) => store.dispatch({ type: 'createSubscription02', args: { selected: i, fn, allPlans: allPlans } }),
  getSubscriptionPlans: _ => getSubscriptionPlans(),
  getInvisiblePlans: _ => getInvisiblePlans(),
  notice: _ => store.dispatch({ 'type': 'notice' }),
  notice02: () => store.dispatch({ 'type': 'notice02', args: { showCloseButton: false }}),
  noticeWithoutCloseButton: () => store.dispatch({ 'type': 'notice', args: { showCloseButton:false }}),
  thankYou: _ => store.dispatch({ type: 'thankYou' }),
  upgradeSubscription : fields => store.dispatch({ 'type': 'upgradeSubscription', 'args': fields }),
  subscribe: _ => store.dispatch({ type: 'subscribe' }),
  persolizationSetUpProfile: (recommendedButtonText, cb, blockName) => {
    if(blockName && blockName === "personalization03") {
      store.dispatch({ type: 'sportsPersolizationSetUpProfile', args: { recommendedButtonText, cb, showCloseButton: false, blockName} })
    } else {
      store.dispatch({ type: 'persolizationSetUpProfile', args: { recommendedButtonText, cb, showCloseButton: false, blockName} })
    }
  },
  forgotPassword: (hardwall, fields) => store.dispatch({ 'type': 'forgotPassword', args: { hardwall, fields } }),
  tvelogin:  _ => store.dispatch({ 'type': 'providerListVerimatrix' })
}

export const error = platform => store.dispatch({ 'type': 'error', 'args': platform })
export const createSubscription = (i, type) => store.dispatch({ type: 'createSubscription', args: { selected: i, type } })
export const login = priceType => store.dispatch({ 'type': 'login', 'args': priceType })
export const newsLogin = priceType => store.dispatch({ 'type': 'newsLogin', 'args': priceType })
export const updateProfile = (fields) => store.dispatch({ 'type': 'updateProfile', 'args': fields })
export const updatePreferences = fields => store.dispatch({ 'type': 'updatePreferences', 'args': fields })
export const changePassword = fields => store.dispatch({ 'type': 'changePassword', args: fields })
export const manageDevices = devices => store.dispatch({ 'type': 'manageDevices', 'args': devices })
export const upgradeSubscription = fields => store.dispatch({ 'type': 'upgradeSubscription', 'args': fields })
export const forgotPassword = (hardwall, fields) => store.dispatch({ 'type': 'forgotPassword', args: { hardwall, fields } })
export const newsForgotPassword = (hardwall, fields) => store.dispatch({ 'type': 'newsForgotPassword', args: { hardwall, fields } })
export const cancelSubscription = ({fields, reasonCountries, enableSelectiveReasons, cancellationLink}) => store.dispatch({ 'type': 'cancelSubscription', args: {fields, reasonCountries, enableSelectiveReasons, cancellationLink} })
export const updateStripePayment = _ => store.dispatch({ 'type': 'updateStripePayment', args: { type: _.type } })
export const billingHistory = fields => store.dispatch({ 'type': 'billingHistory', 'args': fields })
export const notice = _ => store.dispatch({ 'type': 'notice' })
export const episodeOverlay = episode => store.dispatch({ type: 'EpisodeOverlay', 'args': episode })
export const newsEpisodeOverlay = episode => store.dispatch({ type: 'newsEpisodeOverlay', 'args': episode })
export const newsPersolizationSetUpProfile = (recommendedButtonText) => store.dispatch({ type: 'newsPersolizationSetUpProfile', args: { recommendedButtonText } })
export const search = _ => store.dispatch({ 'type': 'search' })
export const hide = _ => store.dispatch({ 'type': 'hide' })
export const purchase = (plan, content, fn, purchase, type) => store.dispatch({ 'type': 'purchase', args: { plan, content, fn, purchase, type } })
export const equipment = _ => store.dispatch({ 'type': 'equipment' })
export const appliedOffers = () => store.dispatch({ "type": 'appliedOffers' })
export const subscribe = () => store.dispatch({ "type": 'subscribe' })
export const customOverlay = ({ showCloseButton, renderContent }) => store.dispatch({ 'type': 'customOverlay', args: { showCloseButton, renderContent } })
export const searchList = _ => store.dispatch({ 'type': 'searchList' })
export const fitnessOnboardingScreen = ({ showCloseButton }) => store.dispatch({ type: 'fitnessOnboardingScreen', args: { showCloseButton } })
export const fitnessSignInCreate = ({ showCloseButton, currentScreen }) => store.dispatch({ type: 'fitnessSignInCreate', args: { showCloseButton, currentScreen } })
export const fitnessChooseAPlan = ({ showCloseButton }) => store.dispatch({ type: 'fitnessChooseAPlan', args: { showCloseButton } })
export const fitnessCheckout = ({ showCloseButton, selectedPlan, fields }) => store.dispatch({ type: 'fitnessCheckout', args: { showCloseButton, selectedPlan, fields } })
export const fitnessSetupProfile = ({ showCloseButton }) => store.dispatch({ type: 'fitnessSetupProfile', args: { showCloseButton } })
export const persolizationSetUpProfile = (recommendedButtonText, cb, blockName) => {
  if(blockName && blockName === "personalization03") {
    store.dispatch({ type: 'sportsPersolizationSetUpProfile', args: { recommendedButtonText, cb, showCloseButton: false, blockName} })
  } else {
    store.dispatch({ type: 'persolizationSetUpProfile', args: { recommendedButtonText, cb, showCloseButton: false, blockName} })
  }
  
}
export const providerList = _ => store.dispatch({ 'type': 'providerList' })
export const workoutEpisodeOverlay = episode => store.dispatch({ type: 'workoutEpisodeOverlay', 'args': episode })
export const viewingRestrictions = (localizedFields) => store.dispatch({ 'type': 'viewingRestrictions', args: { localizedFields } })
export const setupPin = (localizedFields, rating, isSetPin) => store.dispatch({ type: 'setupPin', args: { localizedFields, rating, isSetPin } })
export const pinOverlay = ({ askPin, rating, parentalControlToogle, updateParentalControlToogle }) => store.dispatch({ 'type': 'pinOverlay', args: { askPin, rating, parentalControlToogle, updateParentalControlToogle } })
export const passwordAuth = (enterPinLabel, rating, parentalControlToogle, updateParentalControlToogle) => store.dispatch({ type: 'passwordAuth', args: { enterPinLabel, rating, parentalControlToogle, updateParentalControlToogle } })
export const verifyOTPScreen = ({ email, name, password, phoneNumber, screenName, emailConsent, planId, parentalPin, contentType, whatsappConsent, target }, metaDataMap, onAuthenticate, value, verificationType, data, passwordInput, emailInput) => store.dispatch({ type: 'verifyOTPScreen', args: { email, name, password, phoneNumber, screenName, emailConsent, planId, parentalPin, contentType, whatsappConsent, target, metaDataMap, onAuthenticate, value, verificationType, data, passwordInput, emailInput, showCloseButton:false } })
export const verifyByDoubleOTP = ({ email, name, password, phoneNumber, screenName, emailConsent, planId, parentalPin }, metaDataMap) => store.dispatch({ type: 'verifyByDoubleOTP', args: { email, name, password, phoneNumber, screenName, emailConsent, planId, parentalPin, metaDataMap } })
export const loginEmailPassword = (metaDataMap) => store.dispatch({ type: 'loginEmailPassword', args: { metaDataMap } })
export const updateNumber = (metaDataMap) => store.dispatch({ type: 'updateNumber', args: { metaDataMap, showCloseButton:false} })
export const providerListVerimatrix = _ => store.dispatch({ 'type': 'providerListVerimatrix' })
export const contentPlans = ({ plans, modelType, content, playVideo, isUpgrade, seriesPlanIds}) => store.dispatch({ type: 'contentPlans', args: { plans, modelType, content, playVideo, isUpgrade, seriesPlanIds } })
export const thankYou = _ => store.dispatch({ type: 'thankYou' })
export const ideal = (purchase, metadata) => store.dispatch({ type: 'ideal', args: { purchase, metadata } })
export const createSubscription02 = (i, fn, allPlans) => store.dispatch({ type: 'createSubscription02', args: { selected: i, fn, allPlans: allPlans } })
export const referredUsers = () => store.dispatch({'type':'referredUsers'})
export const purchase02 = (contentDetails, content, fn, plan, transactionType, contentType) => store.dispatch({ 'type': 'purchase02', args: { contentDetails, content, fn, plan, transactionType, contentType } })
export const confirmGooglePayNumber = (walletType) => store.dispatch({ type: 'confirmGooglePayNumber', args: { walletType } })
export const personalizedData = _ => store.dispatch({ 'type': 'personalizedData',args: { showCloseButton:false} })
export const lockedContentPopup = (video, showData, metaDataMap, currentSeason) => store.dispatch({ 'type': 'lockedContentPopup', args: {video, showData, metaDataMap, currentSeason} })
export const contentFilter = (metaDataMap) => store.dispatch({ type: 'contentFilter', args: { metaDataMap, showCloseButton:false} })
export const vppaData = (updateVppaPopup) => store.dispatch({ 'type': 'vppaData', args: {updateVppaPopup, showCloseButton:false} })
export const shareLocation = (updateShareLocation) => store.dispatch({ 'type': 'shareLocation', args: {updateShareLocation, showCloseButton:false} })
export const homeBasedAuthentication = (heading, description, continueBtn, cancelText, zipCodeText, containerBgColor, hbLocatorBgColor, btnBgColor, imageUrl, latitude, longitude ) => store.dispatch({ 'type': 'homeBasedAuthentication', args: {heading, description, continueBtn, cancelText, zipCodeText, containerBgColor, hbLocatorBgColor, btnBgColor, imageUrl, latitude, longitude, showCloseButton:false} })
export const VerimatrixErrorWall = (i, fn) => store.dispatch({ type: 'VerimatrixErrorWall', args: { showCloseButton:false} })