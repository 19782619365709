// import store from '../redux/store'
var gaId = (window.settings.analytics || {}).googleAnalyticsId

// Set Custom googleAnalytics Dimensions
export function setCustomDimensions() {
  // Require googleAnalyticsId
  if (!gaId) {
    console.warn('no googleAnalyticsId provided')
    return
  }
  var ga = window.ga

  const userInfo = {} //store.getState().user.info || {}

  ga('create', gaId, 'auto')  
  ga('set', 'dimension1', `${ userInfo.authenticated ? 'Authenticated' : 'Unauthenticated' } | ${userInfo.subscribed ? 'Subscribed' : 'Unsubscribed'}`)
  // TODO needs update when user introduced
  // if (userInfo.UserDetails && userInfo.UserDetails.userId)
  //   ga('set', 'dimension3', UserDetails.userId)
}

export function trackPageview(path, title) {
  var ga = window.ga
  if (ga) {    
    ga('send', 'pageview', {
      'page': path || '',
      'title': title || ''
    })
  }
}

export function trackEvent(category='', action='', label='') {
  var ga = window.ga
  if (ga) {
    ga('send', 'event', category, action, label)
  }
}

export function initGa() {
  (function(i,s,o,g,r,a,m)
    {
      i['GoogleAnalyticsObject']=r;
      i[r]=i[r]||function()
      {
        (i[r].q=i[r].q||[]).push(arguments)};i[r].l=1*new Date();a=s.createElement(o);
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      }
  )(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

  var ga = window.ga
  if (ga) {
    ga('create', gaId, 'auto')
    ga('send', 'pageview')
  }
}

export function initEnhancedECommerce() {
  var ga = window.ga
  if (ga){
    ga('require', 'ec')
  }
}

export function trackECommerceEvent(event='', eventTrackObj={}) {
  var ga = window.ga
  if (ga) {
    ga(event, eventTrackObj)
  }
}

export function trackEventFunnel(d1, d2) {
  // Sample Input format
  // ga('set', { 'dimension5': 'custom dimension data', 'metric5': 'custom metric data' }); 
    var ga = window.ga
    if (ga) { 
      ga('send', {
        hitType: 'event',
        eventCategory: 'funnel-tracker',
        eventAction: d1,
        eventLabel: d2
      });
     }
}

export function trackEventCustom(d1, d2) {
  // Sample Input format
  // ga('set', { 'dimension5': 'custom dimension data', 'metric5': 'custom metric data' }); 
    var ga = window.ga
    if (ga) { 
      ga('send', {
        hitType: 'event',
        eventCategory: 'tray-event',
        eventAction: d1,
        eventLabel: JSON.stringify(d2)
      });
     }
}