import store from '../redux/page/store'

window["entitlementLoaded"]=function(){
    var requestorID = window.settings.tveSettings.requestor_id;;
    window.AE.setRequestor(requestorID);
    window.AE.checkAuthentication();
}

window["setConfig"]=function(){
    window.AE.getAuthentication();

}
window["displayProviderDialog"]=function(){

}

window["sendTrackingData"]=function(eventType, eventData){
    const user = {
        userId:eventData[2],
        provider:eventData[1]
    }
    //tveSignin(user)
}

window["closeIframeAction"]= function() {
    // window.AE.setSelectedProvider(null);
    document.getElementById('mvpdframe').src="about:blank";
    document.getElementById('mvpddiv').style.display="none";
}

window["createIFrame"]=function(inWidth, inHeight){

    let closeIframe = document.createElement('div');
    closeIframe.innerHTML = 'X';
    closeIframe.onclick = function () {
            window.closeIframeAction()
        };
    document.getElementById('mvpddiv').appendChild(closeIframe);
   
    // Create the iFrame to the specified width and height for the auth page
    let ifrm = document.createElement("IFRAME");
    ifrm.style.width = "700px";
    ifrm.style.height = "500px";
    ifrm.name = "mvpdframe";
    ifrm.id = "mvpdframe";
    ifrm.style.frameborder = "0";
    ifrm.style.border = "0";
    ifrm.style.zIndex = "99999";
    document.getElementById('mvpddiv').appendChild(ifrm);

    
   
    // Copied from the entitlement document
    // Move the div to be centered on the page relative to the size of the iFrame
    document.getElementById('mvpddiv').style.display="block";
    document.getElementById('mvpddiv').style.position="absolute";
    document.getElementById('mvpddiv').style.top="50px";
    document.getElementById('mvpddiv').style.left="50%";
    document.getElementById('mvpddiv').style.zIndex="99999";
    document.getElementById('mvpddiv').style.marginLeft="-"+700/2+"px";
   
    // Force the name into the DOM since it is still not refreshed, for IE
    window.frames["mvpdframe"].name = "mvpdframe";
}

window["setAuthenticationStatus"]=function(status, reason){
    store.dispatch({
        type: 'ADOBE_STATUS',
        payload: false
      })
}

window["setMetadataStatus"]=function(key, encrypted, data){
    if (encrypted) {
        data = window.decrypt(data);
    }
}
