import axios from 'axios'
import Cookies from 'js-cookie'
import { contentFilter } from '~/views/Popup/popup'
import { getToken } from '~/redux/user/tokens'
import pageStore from '~/redux/page/store'
import userStore from '~/redux/user/store'
import { fetchPage } from '~/redux/page/actions'
import { getLocalizedVal } from '~/tools/localization/helpers'
import { ellipsifyByChar} from '~/tools/strings/ellipsify'
import { getRoute } from '~/tools/utils'

export const isContentFilterEnable = () => {
    const { enable } = (window.settings && window.settings.contentFilter) || {};
    return enable;
}

export const recentlySelectedFilterFlag = () => {
    if(Cookies.get('isFilterSelectedRecently') && Cookies.get('isFilterSelectedRecently') !== undefined && Cookies.get('isFilterSelectedRecently') !== 'undefined'){
        return Boolean(Cookies.get('isFilterSelectedRecently'));
    } else {
        return null;
    }
}

export const getFilterTrigger = () => {
    if(Cookies.get('userFilterTrigger') && Cookies.get('userFilterTrigger') !== undefined && Cookies.get('userFilterTrigger') !== 'undefined'){
        return Boolean(Cookies.get('userFilterTrigger'));
    } else {
        return null;
    }
}

export const getSelectedFilter = () => {
    if(Cookies.get('userFilterSelection') && Cookies.get('userFilterSelection') !== undefined && Cookies.get('userFilterSelection') !== 'undefined'){
        return JSON.parse(Cookies.get('userFilterSelection'));
    } else {
        return null;
    }
}

export const getSelectedFilterId = () => {
    const filterOption = getSelectedFilter();
    return filterOption && filterOption.id;
}

export const getUserStateForAnonymousUser = () => {
    let defaultState;
    const userFilterTrigger = getFilterTrigger();
  
    if(userFilterTrigger === true) {
      const filterOption = getSelectedFilter();
      const filterState = {
        "state": ["loggedOut"],     
        "contentFilterId": filterOption && filterOption.id
      }
      defaultState = Buffer.from(JSON.stringify(filterState)).toString('base64'); // Convert into Base64 encode
      defaultState = decodeURIComponent(defaultState);
      
      return defaultState;
    } else {
        defaultState = window.btoa('loggedOut');
        return defaultState;
    }
}

export const getUserStateForExistingUser = () => {
    return sessionStorage.getItem('userStateForExistingUser') || '';
}

export const getContentFilterValue = (filterParam) => {

    if(!(isContentFilterEnable())) {
        return;
    }

    let getNoOfVisits = localStorage.getItem('noOfVisits') ? localStorage.getItem('noOfVisits') : 0;

    if(filterParam === 'noOfVisits') {
        return getNoOfVisits; 
    }

    if(filterParam === 'filterOption') {
        const userFilterTrigger = getFilterTrigger();
        
        return ((userFilterTrigger === true) && getSelectedFilter()) || ''
    }
}

export const getContentFilterLocalizeText = () => {
    const contentFilterTitle = getContentFilterTitleText();
    const contentFilterDescription = getContentFilterDescriptionText();
    const contentFilterReset = getContentFilterResetText();
    const contentFilterSubmit = getContentFilterSubmitText();
    const contentFilterSettingsText = getLocalizedVal(window.settings, 'genericMessages', 'contentFilterSettingsText');

    return {contentFilterTitle, contentFilterDescription, contentFilterReset, contentFilterSubmit, contentFilterSettingsText}
}

export const getContentFilterTitleText = () => {
    return getLocalizedVal(window.settings, 'genericMessages', 'contentFilterTitle');
}

export const getContentFilterDescriptionText = () => {
    return getLocalizedVal(window.settings, 'genericMessages', 'contentFilterDescription');
}

export const getContentFilterResetText = () => {
    return getLocalizedVal(window.settings, 'genericMessages', 'contentFilterReset');
}

export const getContentFilterSubmitText = () => {
    return getLocalizedVal(window.settings, 'genericMessages', 'contentFilterSubmit');
}

export const getContentFilterSettingsText = () => {
    return getLocalizedVal(window.settings, 'genericMessages', 'contentFilterSettingsText');
}

export const getDefaultHeadingId = () => {
    return 'filterContent';
}

export const getDefaultHeadingText = () => {
    return getLocalizedVal(window.settings, 'genericMessages', 'contentFilterDefaultHeading') || 'Filter Content';
}

export const getSkipText = () => {
    return getLocalizedVal(window.settings, 'genericMessages', 'contentFilterReset') || 'No Filter';
}

export const getDefaultHeadingJson = () => {
    const contentFilterDefaultHeading = getDefaultHeadingText();
    return {id: getDefaultHeadingId(), name: ellipsifyByChar(contentFilterDefaultHeading, 17)};
}

export const getSkipJson = () => {
    const contentFilterReset = getSkipText();
    return {id: contentFilterReset, name: ellipsifyByChar(contentFilterReset, 17)};
}

export const getFilterId = (filterOption) => {
    const defaultHeadingJson = getDefaultHeadingJson();
    const skipJson = getSkipJson();
    const defaultHeadingFilter =  [filterOption].filter(x => x && ((x.id === defaultHeadingJson.id) && (x.id !== skipJson.id)));
    const skipFilter =  [filterOption].filter(x => x && ((x.id !== defaultHeadingJson.id) && (x.id === skipJson.id)));
    
    if(defaultHeadingFilter && defaultHeadingFilter.length > 0) {
        return 'NA'; // Filter Id would be 'NA' for Default Heading content filter
    } else if(skipFilter && skipFilter.length > 0) {
        return null; // Filter Id would be null for reset content filter 
    } else {
        return filterOption && filterOption.id;
    }
}

export const setProfileId = (profileId) => {
    profileId && sessionStorage.setItem('profileId', profileId);
}

export const setUserStateForExistingUser = (userState) => {
    userState && sessionStorage.setItem('userStateForExistingUser', userState);
}

export const setUserFilterId = (contentFilterId) => {
    Cookies.set('userContentFilterId', contentFilterId, { expires: 365 });
}

export const setUserFilterSelection = (filterOption) => {
    /** Note: null id would be for selected "No Filter" **/
    if(filterOption && (filterOption.id === null || filterOption.id)) {
        Cookies.set('userFilterSelection', filterOption, { expires: 365 })
    }
}

export const setUserFilterTrigger = (filterTriggerFlag = true) => {
    Cookies.set('userFilterTrigger', filterTriggerFlag, { expires: 365 })
}

export const setIsFilterSelectedRecently = (isRecentlySelected = true) => {
    Cookies.set('isFilterSelectedRecently', isRecentlySelected, { expires: 365 })
}

export const setNoOfVisits = () => {
    
    if(!(isContentFilterEnable())) {
        return;
    }

    const isBrowserOpen = sessionStorage.getItem('isBrowserOpen') || null;
    let getNoOfVisits = localStorage.getItem('noOfVisits') ? localStorage.getItem('noOfVisits') : 0;

    if(!isBrowserOpen) { 
        localStorage.setItem('noOfVisits', ++getNoOfVisits, { expires: 365 });
        sessionStorage.setItem('isBrowserOpen', true)
    }
}

export const fetchCurrentContentFilter = (filterOptions) => {
    const userFilterTrigger = getFilterTrigger();
    
    if(userFilterTrigger === true) {
      return getSelectedFilter();
    } else {
      return filterOptions[0];
    }
}

export const addSkipFilterOption = (contentFilterOptions, requireFilterSelection) => {
    const filterLength = contentFilterOptions && contentFilterOptions.length;
    const defaultHeadingJson = getDefaultHeadingJson();
   
    const defaultHeadingIndex = contentFilterOptions.findIndex(x => x && x.id === defaultHeadingJson.id); 
    
    if(filterLength > 0 && defaultHeadingIndex === -1) { 
        contentFilterOptions.unshift(defaultHeadingJson)
    }

    if(requireFilterSelection === true) {
        return contentFilterOptions;
    } else {
        const skipJson = getSkipJson();
        const skipIndex = contentFilterOptions.findIndex(x => x && x.id === skipJson.id);
        
        if(filterLength > 0 && skipIndex === -1) { 
            contentFilterOptions.push(skipJson)
        }
        return contentFilterOptions;
    }
}

export const removeSkipFilterOption = (contentFilterOptions) => {
    const skipJson = getSkipJson();
    const defaultHeadingJson = getDefaultHeadingJson();
   
   return contentFilterOptions.filter(x => x && ((x.id !== skipJson.id) && (x.id !== defaultHeadingJson.id))); 
}

export const removeDefaultHeadingTextFromDropDown = (contentFilterOptions) => {
    const defaultHeadingJson = getDefaultHeadingJson();
    return contentFilterOptions.filter(x => x && (x.id !== defaultHeadingJson.id)); 
}
export const removeNoFilterTextFromDropDown = (contentFilterOptions) => {
    const skipJson = getSkipJson();
    return contentFilterOptions.filter(x => x && (x.id !== skipJson.id)); 
}

export const updateFilterIdOnProfileId = async (filterOption, authType = '') => {
    const contentFilterId = getFilterId(filterOption);
    setUserFilterId(contentFilterId);

    if(contentFilterId === 'NA') return;

    const profileId = sessionStorage.getItem('profileId');
    const user = userStore.getState().user;
    const route = getRoute();
    
    if(profileId || (user && user.userId)) {
        return getToken().then(async(token) => {
            
            const contentFilterIdJson = { contentFilterId: contentFilterId }
            return await axios({
                method: 'PUT',
                url: `${ window.settings.apiBaseUrl }/user/profile/${profileId}`,
                params: { site: window.settings.internalName },
                data: contentFilterIdJson,
                headers: { 
                    Authorization: token,
                    'x-api-key': window.settings.baseAPIKey 
                }
            }).then( res => {
                const userState = res && res.data && res.data.userState;
                
                setUserFilterSelection(filterOption)
                setUserFilterTrigger(true)
                setIsFilterSelectedRecently(false)
                setUserStateForExistingUser(userState)

                const loginFlag = sessionStorage.getItem('loginFlag');
                if((authType !== 'signup') && (loginFlag !== 'login')) {
                    pageStore.dispatch(fetchPage(route))
                    // sessionStorage.removeItem('sessionFlag');
                }
                
                if(loginFlag === 'login') {
                    sessionStorage.removeItem('loginFlag');
                }
            })
            .catch(err=> {
                console.log(err);
            })
        })
    } else {
        setUserFilterSelection(filterOption)
        setUserFilterTrigger(true)
        setIsFilterSelectedRecently(true)
        
        pageStore.dispatch(fetchPage(route))
        // sessionStorage.removeItem('sessionFlag');
    }
}

export const updateRecentlySelectedFilter = async (authType = '') => {
    if(recentlySelectedFilterFlag() === true) { 
        const filterOption = getSelectedFilter();
        
        if(filterOption && filterOption.id) {
            await updateFilterIdOnProfileId(filterOption, authType);
        }
    }
}

/**
 * 
 * @param {*} resData: Success response after SignUp. 
 * this function is used for SignUp 
 */

export const updateRecentlyFilterIdOnSignUpProfileId = (resData, authType = 'signup') => {
    
    if(!(isContentFilterEnable())) {
        return;
    }

    const profileId = (resData && resData.profileId) || null;
    profileId && setProfileId(profileId);
    
    if(profileId) {
        updateRecentlySelectedFilter(authType);
    }
}

/**
 * 
 * @param {*} resData: Success response after SingIn. 
 * this function is used for SignIn 
 */

export const updateRecentlyFilterIdOnProfileId = (resData, authType = '') => {
    if(!(isContentFilterEnable())) {
        return;
    }

    const { requireFilterSelection } = (window.settings && window.settings.contentFilter) || {};
    const userState = (resData && resData.userState) || null;
    const profileId = (resData && resData.profiles && resData.profiles[0] && resData.profiles[0].profileId) || null;
    profileId && setProfileId(profileId);

    if(userState) {
        const getRecentlySelectedFilterFlag = recentlySelectedFilterFlag();
        setUserStateForExistingUser(userState);
        let decodeUserState = Buffer.from(userState, 'base64').toString('ascii') || null;
        decodeUserState = decodeUserState ? JSON.parse(decodeUserState): ''
        let contentFilterId = decodeUserState && decodeUserState.contentFilterId;

        if(!getRecentlySelectedFilterFlag && contentFilterId === null) {
            return;
        }
        
        if(profileId) {
           
            const chosenContentFilterId = getFilterId(getSelectedFilter());
            /*** Update the Guest User selected filter ID, if logged In user is not selected filter content. If Guest user and logged user filter is same then don't update on user profile **/
            if(chosenContentFilterId !== contentFilterId && !contentFilterId && (getRecentlySelectedFilterFlag === true)) { 
                updateRecentlySelectedFilter(authType);
            } else if(contentFilterId) {
                /** Note: null id would be for selected "No Filter" **/
                const getNoFilterID = requireFilterSelection === true ? null : getSkipText();
                contentFilterId = (contentFilterId === null) ? getNoFilterID : contentFilterId;
              
                const contentFilterObj = (window.settings && window.settings.contentFilter) || {};
                const { filterOptions, enable } = contentFilterObj;
                const filterOption =  (enable && filterOptions && filterOptions.length > 0 && filterOptions.filter(filterOption => filterOption && filterOption.id === contentFilterId)) || null;
                
                setUserFilterTrigger(true);
                setUserFilterSelection(filterOption[0])
                setUserFilterId(contentFilterId);
            }
        }
    }
}

export const isDisplayContentFilterScreen = () => {
    const { noOfVisits } = (window.settings && window.settings.contentFilter) || {};
    const currentNoOfVisits = getContentFilterValue('noOfVisits');
    const currentFilterOption = getContentFilterValue('filterOption');

    if(noOfVisits === null) {
        return false; 
    } else if(!currentFilterOption && !noOfVisits && (currentNoOfVisits >= noOfVisits)) {
        return true;
    } else if(!currentFilterOption && noOfVisits && ((currentNoOfVisits % noOfVisits) === 0)) {
        return true;
    } else {
        return false; 
    }
}

export const displayContentFilterScreen = async () => {
    
    setNoOfVisits();

    if(isDisplayContentFilterScreen()) {
        return contentFilter('');
    }
}