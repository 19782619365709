import React, { useState, useEffect, Fragment } from "react";
import get from "lodash/get";
import iosShareIcon from "~/assets/images/icons/ios-share.png";

import "./style.scss";

const IosSharePopup = () => {
  const isPWAEnabled = get(window, "settings.features.enablePWA", false);
  const [showInstallMessage, setShowInstallMessage] = useState(false);
  const isIos = (() => /iphone|ipad|ipod|mac/.test(window.navigator.userAgent.toLowerCase()))();
  // Detects if device is in standalone mode
  const isInStandaloneMode = (() => get(window, "navigator.standalone", null))();

  const handleInstallPrompt = () => {
    setShowInstallMessage(false);
    sessionStorage.setItem('pwaSessionFlag', true);
  }
  useEffect(() => {
    if(isIos && !isInStandaloneMode && !sessionStorage.getItem('pwaSessionFlag')){
      setShowInstallMessage(true);
    };
  }, [isIos, isInStandaloneMode]); // Checks if should display install popup notification:

  const site = get(window, 'settings.site', "");
  const { 
    iosPwaPromptPrefix = `Install`,
    iosPwaPromptSuffix = "on your Apple device: tap the",
    iosPwaPromptClose = "icon, then click Add to Home Screen (Note: Tap to close the prompt)"
  } = get(window, "settings.navigation.settings.primaryCta", {});

  return isPWAEnabled && showInstallMessage ? (
    <Fragment>
        <div class="ios-share">
          <div className="ios-share-container" onClick={handleInstallPrompt}>
            <span>{iosPwaPromptPrefix} {site} </span> 
            <span>{iosPwaPromptSuffix}</span> 
            &nbsp;<img src={iosShareIcon} alt="share-icon"/>&nbsp;
            <span>{iosPwaPromptClose}</span>
          </div>
        </div>
    </Fragment>
  ) : (
    <Fragment></Fragment>
  );
};

export default IosSharePopup;
