const allLanguageCodes = {
     "ad": "ca" ,
     "ae": "ar" ,
     "af": "fa" ,
     "ag": "en" ,
     "ai": "en" ,
     "al": "sq" ,
     "am": "hy" ,
     "an": "nl" ,
     "ao": "pt" ,
     "ar": "es" ,
     "as": "en" ,
     "at": "de" ,
     "au": "en" ,
     "aw": "nl" ,
     "ax": "sv" ,
     "ba": "bs" ,
     "bb": "en" ,
     "bd": "bn" ,
     "be": "nl" ,
     "bf": "fr" ,
     "bh": "ar" ,
     "bi": "fr" ,
     "bj": "fr" ,
     "bl": "fr" ,
     "bm": "en" ,
     "bn": "ms" ,
     "bo": "es" ,
     "br": "pt" ,
     "bs": "en" ,
     "bt": "dz" ,
     "bv": "no" ,
     "bw": "en" ,
     "by": "be" ,
     "bz": "en" ,
     "ca": "en" ,
     "cc": "en" ,
     "cd": "fr" ,
     "cf": "fr" ,
     "cg": "fr" ,
     "ch": "de" ,
     "ci": "fr" ,
     "ck": "en" ,
     "cl": "es" ,
     "cm": "fr" ,
     "cn": "zh" ,
     "co": "es" ,
     "cr": "es" ,
     "cu": "es" ,
     "cv": "pt" ,
     "cx": "en" ,
     "cy": "el" ,
     "cz": "cs" ,
     "de": "de" ,
     "dj": "fr" ,
     "dk": "da" ,
     "dm": "en" ,
     "do": "es" ,
     "dz": "ar" ,
     "ec": "es" ,
     "ee": "et" ,
     "eg": "ar" ,
     "eh": "ar" ,
     "er": "ti" ,
     "es": "as" ,
     "et": "am" ,
     "fi": "fi" ,
     "fj": "en" ,
     "fk": "en" ,
     "fm": "en" ,
     "fr": "fr" ,
     "ga": "fr" ,
     "gb": "en" ,
     "gd": "en" ,
     "ge": "ka" ,
     "gf": "fr" ,
     "gg": "en" ,
     "gh": "en" ,
     "gi": "en" ,
     "gl": "kl" ,
     "gm": "en" ,
     "gn": "fr" ,
     "gp": "fr" ,
     "gq": "es" ,
     "gr": "el" ,
     "gs": "en" ,
     "gt": "es" ,
     "gu": "en" ,
     "gw": "pt" ,
     "gy": "en" ,
     "hk": "zh" ,
     "hm": "en" ,
     "hn": "es" ,
     "ht": "fr" ,
     "ie": "en" ,
     "il": "he" ,
     "im": "en" ,
     "in": "hi" ,
     "io": "en" ,
     "iq": "ar" ,
     "ir": "fa" ,
     "it": "it" ,
     "je": "en" ,
     "jm": "en" ,
     "jo": "ar" ,
     "jp": "ja" ,
     "ke": "sw" ,
     "kg": "ky" ,
     "kh": "km" ,
     "ki": "en" ,
     "km": "ar" ,
     "kn": "en" ,
     "kp": "ko" ,
     "kr": "ko" ,
     "kw": "ar" ,
     "ky": "en" ,
     "kz": "kk" ,
     "la": "lo" ,
     "lb": "ar" ,
     "lc": "en" ,
     "li": "de" ,
     "lk": "si" ,
     "lr": "en" ,
     "ls": "en" ,
     "lu": "lb" ,
     "ly": "ar" ,
     "ma": "ar" ,
     "mc": "fr" ,
     "md": "ru" ,
     "me": "sr" ,
     "mf": "fr" ,
     "mg": "mg" ,
     "mh": "en" ,
     "ml": "fr" ,
     "mm": "my" ,
     "mo": "zh" ,
     "mp": "ch" ,
     "mq": "fr" ,
     "mr": "ar" ,
     "ms": "en" ,
     "mt": "mt" ,
     "mu": "mf" ,
     "mv": "dv" ,
     "mw": "en" ,
     "mx": "es" ,
     "my": "ms" ,
     "mz": "pt" ,
     "na": "en" ,
     "nc": "fr" ,
     "ne": "fr" ,
     "nf": "en" ,
     "ng": "en" ,
     "ni": "es" ,
     "nl": "nl",
     "no": "nb" ,
     "np": "ne" ,
     "nr": "na" ,
     "nu": "ni" ,
     "nz": "mi" ,
     "om": "ar" ,
     "pa": "es" ,
     "pe": "es" ,
     "pf": "fr" ,
     "pg": "en" ,
     "ph": "en" ,
     "pk": "en" ,
     "pm": "fr" ,
     "pn": "en" ,
     "pr": "es" ,
     "ps": "ar" ,
     "pw": "en" ,
     "py": "es" ,
     "qa": "ar" ,
     "re": "fr" ,
     "rs": "sr" ,
     "rw": "rw" ,
     "sa": "ar" ,
     "sb": "en" ,
     "sc": "fr" ,
     "sd": "ar" ,
     "se": "sv" ,
     "sg": "en" ,
     "sh": "en" ,
     "si": "sl" ,
     "sj": "no" ,
     "sl": "en" ,
     "sm": "it" ,
     "sn": "fr" ,
     "so": "so" ,
     "sr": "nl" ,
     "st": "pt" ,
     "ss": "en" ,
     "sv": "es" ,
     "sy": "ar" ,
     "sz": "en" ,
     "tc": "en" ,
     "td": "fr" ,
     "tf": "fr" ,
     "tg": "fr" ,
     "tj": "tg" ,
     "tk": "tk" ,
     "tl": "pt" ,
     "tm": "tk" ,
     "tn": "ar" ,
     "to": "en" ,
     "tt": "en" ,
     "tv": "en" ,
     "tw": "zh" ,
     "tz": "sw" ,
     "ua": "uk" ,
     "ug": "en" ,
     "um": "en" ,
     "us": "en" ,
     "uy": "es" ,
     "uz": "uz" ,
     "va": "it" ,
     "vc": "en" ,
     "ve": "es" ,
     "vg": "en" ,
     "vi": "en" ,
     "vn": "vi" ,
     "vu": "bi" ,
     "wf": "fr" ,
     "ws": "sm" ,
     "ye": "ar" ,
     "yt": "fr" ,
     "za": "zu" ,
     "zm": "en" ,
     "zw": "en" 
}

export default allLanguageCodes