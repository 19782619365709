import React from 'react'
import get from 'lodash/get'
import viewliftLogo from '~/assets/images/viewlift-logo.png'
import './style.css'

function goToPreviousPage() {
  window.history.go(-1)
}

const Error = (props) => {
  console.error('Error: ', props.error)
  const { response } = props.error
  return (
    <div className="error-screen">
      <a aria-label="Home" className="link" href="/">
        <img className="logo" src={ get(window.settings, 'images.desktopLogo') } alt="logo"/>
      </a>

      <div id="code" className="fade__container">
        <div className="fade__content">404</div>
      </div>

      <div id="message" className="fade__container">
        <p className="fade__content">
          { response && (response.data.message || "Looks like we're having trouble finding this page.") }
        </p>
      </div>

      <a 
        className="fade__container redirect" 
        aria-label="Back" 
        href=""
        onClick={ e => {
          e.preventDefault()
          goToPreviousPage()
        }}
      >
        <div className="icon-container">
          <i className="ion-backspace"/>
        </div>
        <span className="redirect__text fade__content">Back</span>      
      </a>

      <a className="fade__container redirect" aria-label="Home" href="/">
        <div className="icon-container">
          <i className="ion-home"/>
        </div>
        <span className="redirect__text fade__content">Home</span>
      </a>

      <div className="viewlift">
        <p className="label">Powered by</p>
        <a aria-label="Viewlift" target="_blank" href="http://www.viewlift.com/">
          <img src={viewliftLogo} alt="ViewLift"/>
        </a>
      </div>
    </div>
  )
}

export default Error;