const enable = (window.settings.privacy && window.settings.privacy.oneTrust && window.settings.privacy.oneTrust.enable) || null;
const srcUrl = (window.settings.privacy && window.settings.privacy.oneTrust && window.settings.privacy.oneTrust.cdnUrl) || null;
const clientID = (window.settings.privacy && window.settings.privacy.oneTrust && window.settings.privacy.oneTrust.clientID) || null;

//Start OneTrust Cookie Consent
const insertOneTrustCookie = () => {
    if(enable && srcUrl && clientID){
        const script = document.createElement('script');
        script.src = srcUrl;
        script.type = 'text/javascript';
        script.setAttribute('data-domain-script', clientID);
        document.head.appendChild(script);
    
        const functionScript = document.createElement('script');
        functionScript.type = 'text/javascript';
        functionScript.text = function OptanonWrapper() { }
        document.head.appendChild(functionScript);
    }
}

export default insertOneTrustCookie
//End OneTrust Cookie Consent 