const delayTime = 20000; //CookieBot loading 20Sec delay.
const cookieBotRegistrationKey = window.settings.notifications && window.settings.notifications.cookiebotKey;
//Start CookieBot CMP
const initCookieBot = () => {
    return new Promise (function(resolve, reject){
        if(cookieBotRegistrationKey) {
            var script = document.createElement('script');
            script.type= 'text/javascript';
            script.async = true;
            script.id = 'Cookiebot';
            script.src = 'https://consent.cookiebot.com/uc.js?cbid=' + cookieBotRegistrationKey;
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        }   
    })
}

export const deferredInitCookieBot = () => {
    setTimeout((event)=>initCookieBot(event), delayTime);
}

const cookieDeclaration = () => {
    return new Promise (function(resolve, reject){
        if(cookieBotRegistrationKey) {
            var script = document.createElement('script');
            script.type= 'text/javascript';
            script.async = true;
            script.id = 'CookieDeclaration';
            script.src = 'https://consent.cookiebot.com/' + cookieBotRegistrationKey + '/cd.js';
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        }
    })
}

export const deferredCookieDeclaration = () => {
    setTimeout((event)=>cookieDeclaration(event), delayTime);
}
//End CookieBot CMP 