import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import get from 'lodash/get';

const ReactHelmet = ({ triggerPageViewd }) => {
    const isPWAEnabled = get(window, "settings.features.enablePWA", false);
    const isPWALogo = isPWAEnabled ? get(window,"settings.images.pwaLogo", null) : null;
    const fontUrl = get(window, "settings.brand.general.fontUrl", null);
    const fontFamily = get(window, "settings.brand.general.fontFamily", null);
    const defaultLangCode =  get(window, "settings.languages.default.code", null);
    const languageCode = Cookies.get("userLanguageSelection") || (defaultLangCode ? defaultLangCode : "en");
    document.body.style.fontFamily = fontFamily;
    return <Helmet onChangeClientState={newState => triggerPageViewd(newState.title)}>
            <link rel="alternate" href={window.location.href} hreflang={languageCode} />
            {fontUrl && <link href={`${fontUrl}&display=swap`} rel="stylesheet" />}
            <meta name="apple-mobile-web-app-capable" content="yes"/>
            <meta name="apple-mobile-web-app-status-bar-style" content="black"/>
            <meta name="apple-mobile-web-app-title" content={(window.settings && window.settings.site) || ""}/>
            <meta name="theme-color" content={get(window, "settings.brand.general.backgroundColor", "#000000")} />
            {isPWALogo && isPWALogo.pwaLogo72x72 && <link rel="apple-touch-icon" sizes="72x72" href={isPWALogo.pwaLogo72x72}/>}
            {isPWALogo && isPWALogo.pwaLogo96x96 && <link rel="apple-touch-icon" sizes="96x96" href={isPWALogo.pwaLogo96x96}/>}
            {isPWALogo && isPWALogo.pwaLogo128x128 && <link rel="apple-touch-icon" sizes="128x128" href={isPWALogo.pwaLogo128x128}/>}
            {isPWALogo && isPWALogo.pwaLogo144x144 && <link rel="apple-touch-icon" sizes="144x144" href={isPWALogo.pwaLogo144x144}/>}
            {isPWALogo && isPWALogo.pwaLogo152x152 && <link rel="apple-touch-icon" sizes="152x152" href={isPWALogo.pwaLogo152x152}/>}
            {isPWALogo && isPWALogo.pwaLogo192x192 && <link rel="apple-touch-icon" sizes="192x192" href={isPWALogo.pwaLogo192x192}/>}
            {isPWALogo && isPWALogo.pwaLogo384x384 && <link rel="apple-touch-icon" sizes="384x384" href={isPWALogo.pwaLogo384x384}/>}
            {isPWALogo && isPWALogo.pwaLogo512x512 && <link rel="apple-touch-icon" sizes="512x512" href={isPWALogo.pwaLogo512x512}/>}
            {isPWALogo && isPWALogo.pwaLogo512x512 && <link rel="apple-touch-startup-image" href={isPWALogo.pwaLogo512x512}/>}
            <meta name="apple-mobile-web-app-capable" content="yes" />
        </Helmet>;
}
ReactHelmet.propTypes = {
    triggerPageViewd: PropTypes.func.isRequired
}
export default ReactHelmet;